<template>
  <div class="main">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterprise/sbzj' }"
          >申报征集</el-breadcrumb-item
        >
        <el-breadcrumb-item>政策介绍</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="policy">
      <div class="policy-title">广州市中小企业数字化转型城市试点政策介绍</div>
      <p class="policy-p marginbottom2">
        广州市真金白银助推中小企业数字化转型！单家企业最高可领<span
          class="policy-strong"
          >150万</span
        >现金补贴！2024年已有58家先锋企业成功领取超<span class="policy-strong"
          >1000万元</span
        >扶持资金！
      </p>
      <p class="policy-p text-index2 policy-strong fontsize22">
        📋申报门槛自查（5秒速判）
      </p>
      <p class="policy-p">
        ✅ 硬性要求：
      </p>
      <p class="policy-p">
        1️⃣ 制造业实体企业，注册地在广州
      </p>
      <p class="policy-p">
        2️⃣ 属四大重点行业：
      </p>
      <p class="policy-p">
        ▸ 智能网联汽车/零部件
      </p>
      <p class="policy-p">
        ▸ 工业母机和机器人
      </p>
      <p class="policy-p">
        ▸ 时尚美妆
      </p>
      <p class="policy-p">
        ▸ 定制家居/服装/箱包
      </p>
      <p class="policy-p">
        ▸ 以上行业产业链上下游企业
      </p>
      <p class="policy-p">
        3️⃣ 企业规模（二选一）：
      </p>
      <p class="policy-p">
        → 年营收＜4亿元
      </p>
      <p class="policy-p">
        → 员工人数＜1000人
      </p>
      <p class="policy-p">
        4️⃣ 必须与政府认证的"数字化牵引单位"签约合作
      </p>
      <p class="policy-p marginbottom2">
        （已公示牵引单位企业名单见下方“避坑指南”）
      </p>
      <p
        class="policy-p flex vertical fontred policy-strong fontsize22 marginleft2"
      >
        <i
          class="el-icon-d-arrow-right"
          style="color: red;font-weight: 600;"
        ></i>
        申报流程:
      </p>
      <p class="policy-p fontblue policy-strong">
        一、意向改造企业征集申报
      </p>
      <p class="policy-p">
        在“穗智转”（<el-link
          type="primary"
          style="text-indent: 0;"
          href="https://suizz.ceprei.com:888/#/enterprise/sbzj"
          target="_self"
          >https://suizz.ceprei.com:888/#/enterprise/sbzj</el-link
        >）进行试点企业意向填报。网页路径：申报征集—试点企业改造意向摸查。
      </p>
      <!-- <ul class="policy-ul type-num">
        <li>
          在“穗智转”（<el-link
            type="primary"
            style="text-indent: 0;"
            href="https://suizz.ceprei.com:888/#/enterprise/sbzj"
            target="_self"
            >https://suizz.ceprei.com:888/#/enterprise/sbzj</el-link
          >）进行试点企业意向填报。网页路径：申报征集—试点企业改造意向摸查。
        </li>
        <li>
          选择实施单位
        </li>
        <li>
          选择数字化产品和实施单位，并与实施单位、牵引单位签订多方数字化改造合同。（实施单位和牵引单位可以是同一个主体）
        </li>
      </ul> -->
      <p class="policy-p fontblue policy-strong">
        二、选择数字化产品
      </p>
      <p class="policy-p">
        选择数字化产品（<el-link
          type="primary"
          style="text-indent: 0;"
          href="https://suizz.ceprei.com/zstp/xqcj"
          target="_self"
          >https://suizz.ceprei.com/zstp/xqcj</el-link
        >），与实施单位、牵引单位签订多方数字化改造合同。（实施单位和牵引单位可以是同一个主体）
      </p>
      <!-- <ul class="policy-ul type-num">
        <li>
          在“穗智转”（<el-link
            type="primary"
            style="text-indent: 0;"
            href="https://suizz.ceprei.com:888/#/enterprise/sbzj"
            target="_self"
            >https://suizz.ceprei.com:888/#/enterprise/sbzj</el-link
          >）上提交数字化改造项目备案，网页路径：申报征集—数字化改造项目备案。
        </li>
        <li>
          改造后数字化水平测评
        </li>
        <li>
          第三方诊断机构线下诊断，数字化水平须达到二级才能享受资金扶持。
        </li>
      </ul> -->
      <p class="policy-p fontblue policy-strong">
        三、数字化改造项目备案
      </p>
      <p class="policy-p">
        在“穗智转”（<el-link
          type="primary"
          style="text-indent: 0;"
          href="https://suizz.ceprei.com:888/#/enterprise/sbzj"
          target="_self"
          >https://suizz.ceprei.com:888/#/enterprise/sbzj</el-link
        >）上提交数字化改造项目备案，网页路径：申报征集—数字化改造项目备案。
      </p>
      <!-- <ul class="policy-ul type-num">
        <li>
          在广州市工业和信息化发展专项资金项目管理系统（<el-link
            type="primary"
            style="text-indent: 0;"
            href="https://shenbao.gxj.gz.gov.cn/Account/index"
            target="_self"
            >https://shenbao.gxj.gz.gov.cn/Account/index</el-link
          >）提交项目验收申请。
        </li>
      </ul> -->
      <p class="policy-p fontblue policy-strong">
        四、改造后数字化水平测评
      </p>
      <p class="policy-p">
        第三方诊断机构线下诊断，数字化水平须达到二级才能享受资金扶持。
      </p>
      <p class="policy-p fontblue policy-strong">
        五、数字化改造项目验收及资金拨付
      </p>
      <p class="policy-p marginbottom2">
        在广州市工业和信息化发展专项资金项目管理系统（<el-link
          type="primary"
          style="text-indent: 0;"
          href="https://shenbao.gxj.gz.gov.cn/Account/index"
          target="_self"
          >https://shenbao.gxj.gz.gov.cn/Account/index</el-link
        >）提交项目验收申请。
      </p>
      <p class="policy-p text-index2 policy-strong fontsize22">
        🚨避坑指南
      </p>
      <p class="policy-p fontred">
        ❗ 与牵引单位签订合同时间必须满足：
      </p>
      <ul class="policy-ul">
        <li>直接签约：2023年10月11日之后</li>
        <li>
          三方签约：在牵引单位公示日期之后（2024.8.30第一批公示，名单见<el-link
            type="primary"
            href="https://gxj.gz.gov.cn/yw/tzgg/content/post_9841932.html"
            target="_self"
            >https://gxj.gz.gov.cn/yw/tzgg/content/post_9841932.html</el-link
          >；2024.11.25第二批公示，名单见<el-link
            type="primary"
            href="https://gxj.gz.gov.cn/yw/tzgg/content/post_9991482.html"
            target="_self"
            >https://gxj.gz.gov.cn/yw/tzgg/content/post_9991482.html</el-link
          >）
        </li>
      </ul>
      <p class="policy-p fontred">
        ❗ 企业信用无污点：
      </p>
      <p class="policy-p">
        → 未被列入经营异常/失信名单
      </p>
      <p class="policy-p">
        → 近3年无重大安全事故/偷税漏税
      </p>
      <p class="policy-p marginbottom2 fontred">
        ❗ 同一项目未拿过其他财政补贴
      </p>
      <p class="policy-p text-index2 policy-strong fontsize22">
        ⭐加分秘籍（优先审批）
      </p>
      <p class="policy-p">
        ✔ 国家专精特新"小巨人"企业
      </p>
      <p class="policy-p">
        ✔ 省级创新型/高成长中小企业
      </p>
      <p class="policy-p marginbottom2">
        ✔ 有上市计划或正在辅导期企业
      </p>
      <p class="policy-p text-index2 policy-strong fontsize22">
        💰政府买单清单
      </p>
      <p class="policy-p">
        → 数字化转型软件（ERP/MES系统等）
      </p>
      <p class="policy-p">
        → 云服务及安装调试费
      </p>
      <p class="policy-p">
        → 路由器/传感器/防火墙等硬件
      </p>
      <p class="policy-p marginbottom2">
        → 工业控制系统及数据采集设备
      </p>
      <p class="policy-p">
        举个栗子：
      </p>
      <p class="policy-p">
        某汽配厂：采购20台工业传感器（补8.6万）
      </p>
      <p class="policy-p">
        某服装厂：部署智能排产系统（补32万）
      </p>
      <p class="policy-p marginbottom2">
        某家居企业：搭建云MES平台（补58万）
      </p>
      <p class="policy-p text-index2 policy-strong fontsize22">
        ⏳ 温馨提示
      </p>
      <p class="policy-p">
        ★ 未填报改造意向的企业抓紧填报
      </p>
      <p class="policy-p">
        ★ 未签约企业可紧急对接政策顾问匹配牵引单位
      </p>
      <p class="policy-p">
        ★ 已启动改造的企业速补交三方协议
      </p>
      <p class="policy-p">
        ★ 中小数转政策集合：<el-link
          type="primary"
          href="https://gxj.gz.gov.cn/zt/zdzt/axqyszhzxzq/index.html"
          target="_self"
          >https://gxj.gz.gov.cn/zt/zdzt/axqyszhzxzq/index.html</el-link
        >
      </p>
      <p class="policy-p marginbottom2">
        <!-- ☎ 政策咨询通道：020-83726610、020-83726607 -->
        ☎ 政策咨询通道：020-83723312、020-83726607
      </p>
      <p class="policy-p text-index2 policy-strong fontsize22">
        💡一句话总结
      </p>
      <p class="policy-p fontblue policy-strong">
        只要你是广州制造企业，没上黑名单、没拿过其他补贴，现在买软件/系统，政府最高报销50%费用！大家不要错过啦，未参与意向填报的企业抓紧完成。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    handleGoWebsite() {
      window.open("https://gxj.gz.gov.cn/zt/zdzt/axqyszhzxzq/");
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  width: 73%;
  margin: 0 auto 30px;
}
.policy {
  width: 73%;
  margin: 0 auto;
  padding: 0 90px 50px;
  background-color: #fff;
  box-sizing: border-box;
  font-size: 18px;
  .el-link {
    font-size: 18px;
  }
  .policy-strong {
    font-weight: 600;
  }
  .policy-ul {
    line-height: 1.8;
    list-style: inside;
    &.type-num {
      list-style: decimal;
      margin-left: 20px;
      margin-bottom: 30px;
    }
  }
  .policy-p {
    line-height: 1.8;
    &.text-index2 {
      text-indent: 2rem;
    }
    &.marginleft2 {
      margin-left: 2rem;
    }
    &.marginbottom2 {
      margin-bottom: 2rem;
    }
    &.fontred {
      color: red;
    }
    &.fontblue {
      color: #2d64cf;
    }
    &.fontsize22 {
      font-size: 22px;
    }
  }

  .policy-title {
    padding: 30px;
    border-bottom: 1px solid #f2f2f2;

    font-family: Microsoft YaHei;
    font-size: 32px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
    color: #333333;
    margin-bottom: 15px;
  }
}
</style>
