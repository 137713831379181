<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div class="top-nav">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                :to="{
                  path: '/company/sbzj',
                }"
                >申报征集</el-breadcrumb-item
              >
              <el-breadcrumb-item
                :to="{
                  path: '/company/sthzhb/list',
                }"
                >产业生态联合体企业备案申请</el-breadcrumb-item
              >
              <el-breadcrumb-item>{{
                $route.query.type == 1 ? "填报" : "详情"
              }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="form-container">
            <div class="form-title-content">
              <h1 class="form-title">
                产业生态联合体企业备案申请
              </h1>
              <div class="line"></div>
            </div>
            <el-form
              label-position="left"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">一、企业基本信息</h5>
              <el-form-item label="单位名称" prop="pullUserId">
                <el-select
                  v-model="ruleForm.pullUserId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="getCompanyList"
                  :loading="loading"
                  @change="handleCompanyChange"
                  :disabled="isDetail"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in companyList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="统一社会信用代码" prop="creditCode">
                <el-input v-model="ruleForm.creditCode" disabled></el-input>
              </el-form-item>
              <el-form-item label="生态角色" prop="serviceActor">
                <el-radio-group
                  v-model="ruleForm.serviceActor"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="数字化集成企业"></el-radio>
                  <el-radio label="产业生态企业"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="上年营业收入（万元）" prop="lastYearIncome">
                <el-input
                  v-model="ruleForm.lastYearIncome"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="在广州团队人数" prop="teamNum">
                <el-input
                  v-model="ruleForm.teamNum"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="主要产品/服务" prop="masterService">
                <el-input
                  v-model="ruleForm.masterService"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="获得的知识产权数量（含专利软件著作权）"
                prop="intellectualPropertyNum"
              >
                <el-input
                  v-model="ruleForm.intellectualPropertyNum"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="获得国家、省、市级相关荣誉资质"
                prop="honorsNum"
              >
                <el-input
                  type="textarea"
                  v-model="ruleForm.honorsNum"
                  :disabled="isDetail"
                  :autosize="{ minRows: 6 }"
                  maxlength="300"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="单位简介（100字以内）" prop="comment">
                <el-input
                  type="textarea"
                  v-model="ruleForm.comment"
                  :disabled="isDetail"
                  :autosize="{ minRows: 5 }"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="合作协议" prop="cooperationAgreement">
                <el-upload
                  :action="uploadUrl"
                  :on-success="handleSuccess1"
                  :on-change="handleChange"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview"
                  :file-list="fileList1"
                  :limit="5"
                >
                  <el-button
                    size="small"
                    type="primary"
                    @click.stop="getTemplate"
                    >下载模板</el-button
                  >
                  <el-button size="small" type="success" :disabled="isDetail"
                    >上传</el-button
                  >
                  <div
                    slot="tip"
                    class="el-upload__tip"
                    style="text-wrap: nowrap;"
                  >
                    注:最多可上传5个文件,文件上传大小不能超过20M
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="生态企业营业执照及法人身份证复印件（授权人需要提供授权说明）"
                label-width="100%"
              ></el-form-item>
              <el-form-item label="" prop="license">
                <el-upload
                  :action="uploadUrl"
                  :on-success="handleSuccess2"
                  :on-change="handleChange"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview"
                  :file-list="fileList2"
                  :limit="5"
                >
                  <el-button size="small" type="success" :disabled="isDetail"
                    >上传</el-button
                  >
                  <div
                    slot="tip"
                    class="el-upload__tip"
                    style="text-wrap: nowrap;"
                  >
                    注:最多可上传5个文件,文件上传大小不能超过20M
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="生态企业上年度财务审计报告（或税审报告、加盖公章的财务报表）关键页"
                label-width="100%"
              ></el-form-item>
              <el-form-item label="" prop="auditReport">
                <el-upload
                  :action="uploadUrl"
                  :on-success="handleSuccess3"
                  :on-change="handleChange"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview"
                  :file-list="fileList3"
                  :limit="5"
                >
                  <el-button size="small" type="success" :disabled="isDetail"
                    >上传</el-button
                  >
                  <div
                    slot="tip"
                    class="el-upload__tip"
                    style="text-wrap: nowrap;"
                  >
                    注:最多可上传5个文件,文件上传大小不能超过20M
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label="信用中国报告关键页" prop="creditChinaReport">
                <el-upload
                  :action="uploadUrl"
                  :on-success="handleSuccess4"
                  :on-change="handleChange"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview"
                  :file-list="fileList4"
                  :limit="5"
                >
                  <el-button size="small" type="success" :disabled="isDetail"
                    >上传</el-button
                  >
                  <div
                    slot="tip"
                    class="el-upload__tip"
                    style="text-wrap: nowrap;"
                  >
                    注:最多可上传5个文件,文件上传大小不能超过20M
                  </div>
                </el-upload>
              </el-form-item>
            </el-form>
            <el-form
              label-position="left"
              :model="ruleForm"
              :rules="rules"
              ref="auditForm"
              label-width="250px"
              class="ruleForm"
              v-if="isDetail"
            >
              <h5 class="subheadings">二、审核</h5>
              <el-form-item label="审核状态" prop="auditStatus">
                <el-radio-group
                  v-model="ruleForm.auditStatus"
                  size="medium"
                  disabled
                >
                  <el-radio :label="1">待审核</el-radio>
                  <el-radio :label="2">审核通过</el-radio>
                  <el-radio :label="3">审核拒绝</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="原因" prop="auditReason">
                <el-input
                  type="textarea"
                  v-model="ruleForm.auditReason"
                  :autosize="{ minRows: 5 }"
                  placeholder=""
                  disabled
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="btns">
            <el-button @click="handleClose" class="cancel-btn btn"
              >关闭</el-button
            >
            <el-button @click="saveDraft" class="draft-btn btn" v-if="!isDetail"
              >保存草稿</el-button
            >
            <el-button @click="getDraft" class="draft-btn btn" v-if="!isDetail"
              >获取草稿</el-button
            >
            <el-button
              @click="beforeSubmit"
              type="primary"
              class="btn"
              v-if="!isDetail"
              >提交</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configs from "@/config/index";
import {
  saveDraft,
  getDraft,
  addOrUpdateEcological,
  getCompanyList,
  getEcologicalDetail,
} from "@/api/pts";
export default {
  data() {
    return {
      ruleForm: {
        pullUnit: "",
        creditCode: "",
        serviceActor: "",
        lastYearIncome: "",
        teamNum: "",
        masterService: "",
        intellectualPropertyNum: "",
        honorsNum: "",
        comment: "",
        cooperationAgreement: "",
        license: "",
        auditReport: "",
        creditChinaReport: "",
        userId: "",
      },

      auditForm: {},

      rules: {
        pullUserId: [
          { required: true, message: "请输入单位名称", trigger: "change" },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        serviceActor: [
          { required: true, message: "请输入生态角色", trigger: "change" },
        ],
        lastYearIncome: [
          {
            required: true,
            message: "请输入上年营业收入（万元）",
            trigger: "change",
          },
        ],
        teamNum: [
          {
            required: true,
            message: "请输入在广州团队人数",
            trigger: "change",
          },
        ],
        masterService: [
          { required: true, message: "请输入主要产品/服务", trigger: "change" },
        ],
        intellectualPropertyNum: [
          {
            required: true,
            message: "请输入获得的知识产权数量（含专利软件著作权）",
            trigger: "change",
          },
        ],
        honorsNum: [
          {
            required: true,
            message: "请输入获得国家、省、市级相关荣誉资质",
            trigger: "change",
          },
        ],
        comment: [
          {
            required: true,
            message: "请输入单位简介（100字以内）",
            trigger: "change",
          },
        ],
        cooperationAgreement: [
          {
            required: true,
            message: "请上传合作协议",
            trigger: "change",
          },
        ],
        license: [
          {
            required: true,
            message:
              "请上传生态企业营业执照及法人身份证复印件（授权人需要提供授权说明）",
            trigger: "change",
          },
        ],
        auditReport: [
          {
            required: true,
            message:
              "请上传生态企业上年度财务审计报告（或税审报告、加盖公章的财务报表）关键页",
            trigger: "change",
          },
        ],
        creditChinaReport: [
          {
            required: true,
            message: "请上传信用中国报告关键页",
            trigger: "change",
          },
        ],
      },

      uploadUrl: "", // 图片上传路径
      isDetail: false,
      loadingStatus: null,
      loading: false,
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      companyList: [],
    };
  },
  created() {
    this.uploadUrl = `${configs.baseUrl2}/api/v1/common/upload`;
  },
  mounted() {
    // 1是填报，2是查看
    if (this.$route.query.type == 1) {
      this.isDetail = false;
      if (this.$route.query.id) {
        this.getEcologicalDetail();
      }
    } else if (this.$route.query.type == 2) {
      this.isDetail = true;
      this.getEcologicalDetail();
    }
  },
  methods: {
    handlePreview(file) {
      let url = file.url;
      let linkDom = document.createElement("a");
      linkDom.href = url;
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
    },
    getEcologicalDetail() {
      getEcologicalDetail({ id: this.$route.query.id }).then((res) => {
        console.log("res", res);
        this.ruleForm = res.data;
        this.fileList1 = JSON.parse(this.ruleForm.cooperationAgreement);
        this.fileList2 = JSON.parse(this.ruleForm.license);
        this.fileList3 = JSON.parse(this.ruleForm.auditReport);
        this.fileList4 = JSON.parse(this.ruleForm.creditChinaReport);
        this.getCompanyList(res.data.pullUnit);
      });
    },
    handleSuccess1(response, type) {
      if (response.code == 200) {
        this.fileList1.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.ruleForm.cooperationAgreement = JSON.stringify(this.fileList1);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleSuccess2(response, type) {
      if (response.code == 200) {
        this.fileList2.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.ruleForm.license = JSON.stringify(this.fileList2);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleSuccess3(response, type) {
      if (response.code == 200) {
        this.fileList3.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.ruleForm.auditReport = JSON.stringify(this.fileList3);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleSuccess4(response, type) {
      if (response.code == 200) {
        this.fileList4.push({
          name: response.originalFilename,
          url: response.url,
        });
        this.ruleForm.creditChinaReport = JSON.stringify(this.fileList4);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleChange(file, fileList) {
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        this.loadingStatus.close();
      }
    },
    handleExceed() {
      this.$message.error("上传文件不能超过5个");
    },
    getTemplate() {
      // let url =
      //   "https://www.szz.manage.beifen.hcxtec.com/files/txt/合作协议书.docx";
      let url = "https://suizz.ceprei.com:9204/files/txt/合作协议书.docx";
      let linkDom = document.createElement("a");
      linkDom.href = url;
      linkDom.target = "_blank";
      linkDom.download = "合作协议书.docx";
      linkDom.style.display = "none";
      linkDom.click();
    },
    beforeSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.ruleForm.userId = JSON.parse(
            sessionStorage.getItem("ptsInfo")
          ).userId;
          // 如果有id说明是编辑这个时候要传一个ID过去
          if (this.$route.query.id) {
            this.ruleForm.id = this.$route.query.id;
          }
          addOrUpdateEcological(this.ruleForm)
            .then((res) => {
              console.log("res", res);
              if (res.code == 200) {
                this.$message.success("申请成功");
              }
            })
            .catch((err) => {
              console.log("add or edit error", err);
            })
            .finally(() => {
              this.$router.push({ path: "/company/sthzhb/list" });
            });
        }
      });
    },
    handleClose() {
      this.$router.push({ name: "Sbzj" });
    },
    getCompanyList(companyName) {
      getCompanyList({ companyName }).then((res) => {
        console.log("res", res);
        this.companyList = res.data;
      });
    },
    handleCompanyChange(userId) {
      this.companyList.forEach((item) => {
        if (item.userId == userId) {
          this.ruleForm.pullUnit = item.name;
          this.ruleForm.creditCode = item.jgm;
        }
      });
    },
    saveDraft() {
      // 保存草稿的时候都不保存id
      if (this.ruleForm.id) {
        delete this.ruleForm.id;
      }
      let pdata = {
        type: 7,
        content: JSON.stringify(this.ruleForm),
      };
      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存草稿成功");
        } else {
          this.$message.error("保存草稿失败");
        }
      });
    },
    getDraft() {
      this.getCompanyList("");
      let pdata = {
        type: 7,
      };
      getDraft(pdata).then((res) => {
        if (res.code == 1 && res.data != null) {
          this.$message.success("获取成功");
          let data = JSON.parse(res.data.content);
          this.ruleForm = data;
          this.fileList1 = JSON.parse(data.cooperationAgreement);
          this.fileList2 = JSON.parse(data.license);
          this.fileList3 = JSON.parse(data.auditReport);
          this.fileList4 = JSON.parse(data.creditChinaReport);
        } else {
          this.$message.error("获取失败");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ss-input {
  ::v-deep .el-input__inner {
    height: 18px;
    width: 100px;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #efefef;
  }
}
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;

        .top-nav {
          margin-bottom: 30px;
        }

        .form-container {
          width: 100%;
          padding: 40px 200px;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          margin-top: 30px;

          .form-title-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .form-title {
              font-family: Microsoft YaHei;
              font-size: 24px;
              font-weight: bold;
              line-height: normal;
              text-align: center;
              color: #333333;

              margin: 0;
              margin-bottom: 10px;
            }
            .line {
              width: 150px;
              height: 4px;
              border-radius: 2px;
              background: #205dc3;
            }
          }

          h5.subheadings {
            font-family: Microsoft YaHei;
            font-size: 22px;
            font-weight: bold;
            font-variation-settings: "opsz" auto;
            color: #333333;

            margin-top: 80px;
            margin-bottom: 40px;
          }
          .ruleForm {
            .sf-content {
              display: flex;
              // align-items: center;
              span {
                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 18px;
                color: #666666;

                margin-left: 50px;
              }
            }

            .zmcl-content {
              .zmcl-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                // height: 100px;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid #dcdfe6;
                margin-top: 20px;

                .zmcl-content-left {
                  width: 70%;

                  font-family: Microsoft YaHei;
                  font-size: 16px;
                  line-height: 26px;
                  color: #2282f9;
                }
              }
            }

            .sppgfj-upload {
              display: flex;
              justify-content: space-between;
              align-items: center;

              width: 100%;
              // height: 100px;
              padding: 15px;
              box-sizing: border-box;
              border-radius: 5px;
              border: 1px solid #dcdfe6;
              margin-top: 20px;

              .sppgfj-content-left {
                width: 70%;

                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 26px;
                color: #2282f9;
              }
            }

            .radio-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qyms-radio-group {
              display: flex;
              flex-direction: column;

              /deep/.qyms-radio-option {
                display: flex;
                // align-items: center;
                margin-bottom: 30px;
                .el-radio__label {
                  display: inline-block;
                  display: flex;
                  flex-direction: column;
                  .qyms-description {
                    // display: inline-block;
                    width: 100%;
                    // height: 100px;
                    margin-top: 10px;

                    font-family: Microsoft YaHei;
                    font-size: 14px;
                    line-height: 26px;
                    color: #666666;
                    text-wrap: wrap;
                  }
                }
              }
            }

            .checkbox-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                // margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qydw-content {
              .qydw-radio {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                margin-left: 30px;

                .qydw-item {
                  width: 350px;
                  display: flex;
                  flex-direction: column;
                  margin-right: 30px;

                  .qydw-item-title {
                    font-family: Microsoft YaHei;
                    font-size: 18px;
                    font-weight: bold;
                    font-variation-settings: "opsz" auto;
                    color: #333333;

                    margin-bottom: 30px;
                  }

                  .qydw-radio-option {
                    margin-bottom: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cancel-btn {
  height: 38px;
  background-color: #e2e2e2;
  border: none;
  color: #333;
  &:hover {
    background-color: #efefef;
    color: #333;
  }
}
.btns {
  margin-top: 38px;
  .draft-btn {
    height: 38px;
    background-color: #ea7100;
    border: none;
    color: #fff;
    &:hover {
      background-color: #ffb774;
      color: #fff;
    }
  }
  .btn {
    margin-right: 10px;
  }
}

.dellink {
  cursor: pointer;
  margin-left: 5px;
  color: #409eff;
  &:hover {
    color: #66b1ff;
  }
}
</style>
