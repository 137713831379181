<template>
  <div class="Meannav">
    <div class="new_z_r_top">
      <div class="new_z_r_top_l flex bewteen-wrapper" style="width: 100%">
        <div
          class="new_z_r_content flex vertical"
          :style="is_stu != 3 ? 'justify-content:space-between;' : ''"
        >
          <div
            v-for="(item, index) in meanList"
            :key="index"
            :class="
              activeIndex == index
                ? 'new_z_r_top_mei pointer new_z_r_top_mei_bj flex bewteen-wrapper'
                : 'new_z_r_top_mei pointer flex bewteen-wrapper'
            "
            :style="is_stu != 3 ? '' : 'margin-right:24px;'"
            @click="meanChange(item, index)"
          >
            <div class="new_z_r_top_mei_img">
              <!-- <img class="icon" :src="item.icon | staticMedia" /> -->
              <div class="icon" :style="$bgImg(item.icon)"></div>
            </div>
            <h2 class="new_z_r_top_mei_h2">{{ item.name }}</h2>
            <div
              id="shitN"
              v-if="
                (item.name == '待我审批' || item.name == '审批中心') &&
                  activeIndex != index
              "
            >
              <div class="left-circle"></div>
              <div id="shit_NNC">
                {{ spnum }}
              </div>
              <div class="right-circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Get_zf_Count, Get_pts_Count, GetTrade } from "@/api/login";
export default {
  name: "MeanHeader",
  data() {
    return {
      activeIndex: null,
      is_stu: 0,
      meanList: [],
      spnum: 0,
      userId: "",
      SonPTSMeanList: [
        {
          name: "四化诊断",
          icon: "/pts_shzd.png",
          router: "/company/diagnose",
          alia: "diagnose",
        },
      ],
      PTSMeanList: [
        {
          name: "企业信息",
          icon: "/pts_qyxx.png",
          router: "/company/companyInfo",
          alia: "CompanyInfo",
        },
        {
          name: "经典案例",
          icon: "/pts_jdal.png",
          router: "/company/classicCases",
          alia: "ClassicCases",
        },
        {
          name: "需求信息",
          icon: "/pts_xqxx.png",
          router: "/company/needinfo",
          alia: "NeedInfo",
        },
        {
          name: "产品发布",
          icon: "/pts_cpfb.png",
          router: "/company/product",
          alia: "Product",
        },
        {
          name: "解决方案",
          icon: "/pts_jjfa.png",
          router: "/company/solution",
          alia: "Solution",
        },
        {
          name: "四化诊断",
          icon: "/pts_shzd.png",
          router: "/company/diagnose",
          alia: "diagnose",
        },
        {
          name: "四化改造",
          icon: "/pts_shgz.png",
          router: "/company/xxtb",
          alia: "Xxtb",
        },
        {
          name: "待我审批",
          icon: "/zf_spzx.png",
          router: "/company/dwsp",
          alia: "Dwsp",
        },
        {
          name: "申报征集",
          icon: "/pts_sbzj.png",
          router: "/company/sbzj",
          alia: "Sbzj",
        },
      ],

      QYMeanList: [
        {
          name: "需求发布",
          // icon: "/xiangguan.png",
          icon: "/qy_xqfb.png",
          router: "/requirePublic",
          alia: "RequirePublic",
        },
        {
          name: "“四化”能力自评估",
          // icon: "/sic2.png",
          icon: "/qy_sh.png",
          router: "/siHuaQuestion",
          alia: "SiHuaQuestion",
        },
        // {
        //   name: "制造业中小企业数字化水平评估问卷",
        //   // icon: "/szh_wj_nav.png",
        //   icon: "/qy_szh.png",
        //   router: "/shuZiHuaQuestion",
        //   alia: "ShuZiHuaQuestion",
        // },
        {
          name: "中小企业数字化水平自评测",
          // icon: "/szh_wj_nav.png",
          icon: "/qy_szh.png",
          router: "/selfAssessmentQuestion",
          alia: "selfAssessmentQuestion",
        },
        {
          name: "申报征集",
          icon: "/pts_sbzj.png",
          router: "/enterprise/sbzj",
          alia: "enterpriseSbzj",
        },
      ],
      // 临时修改 2  - 增加
      WJMeanList: [
        // {
        //   name: "教学评价",
        //   // icon: "/xiangguan.png",
        //   icon: "",
        //   router: "/requirePublic",
        //   alia: "RequirePublic",
        // },
        {
          name: "教师能力自评估",
          // icon: "/sic2.png",
          icon: "/qy_xqfb.png",
          router: "/siHuaQuestion",
          alia: "SiHuaQuestion",
        },
        {
          name: "数字化评分调查问卷系统",
          // icon: "/szh_wj_nav.png",
          icon: "/qy_szh.png",
          router: "/shuZiHuaQuestion",
          alia: "ShuZiHuaQuestion",
        },
      ],
      // 临时修改 2 介素

      // 政府端用的组件的方式写的，这个方式不适用政府端。放其他端了
      ZFMeanList: [
        {
          name: "四化诊断",
          icon: "/zf_shzd.png",
          // router: "/government/dxxcx",
          router: "",
          alia: "SiHuaZhenDuan",
        },
        {
          name: "四化改造",
          icon: "/zf_shgz.png",
          // router: "/government/xxcx",
          router: "",
          alia: "SiHuaGaiZao",
        },
        {
          name: "新闻动态",
          icon: "/zf_xwdt.png",
          router: "",
        },
        {
          name: "四化政策",
          icon: "/zf_shzc.png",
          router: "",
        },
        {
          name: "名单公示",
          icon: "/zf_mdgs.png",
          router: "",
        },
        {
          name: "行业解决方案",
          icon: "/zf_jjfa.png",
          router: "",
        },
        {
          name: "优秀案例",
          icon: "/zf_yxal.png",
          router: "",
        },
        {
          name: "申报征集",
          icon: "/pts_sbzj.png",
          router: "/government/sbzj",
        },
        {
          name: "课程发布",
          icon: "/zf_kcfb.png",
          router: "/government/course",
        },
        {
          name: "审批中心",
          icon: "/zf_spzx.png",
          router: "/government/approval",
        },
      ],
      // 政府端用的组件的方式写的，这个方式不适用政府端。放其他端了
      SZHZFMeanList: [
        {
          name: "数字化诊断",
          icon: "/zf_shzd.png",
          // router: "/government/dxxcx",
          router: "",
          alia: "SiHuaZhenDuan",
        },
        {
          name: "四化改造",
          icon: "/zf_shgz.png",
          // router: "/government/xxcx",
          router: "",
          alia: "SiHuaGaiZao",
        },
        {
          name: "新闻动态",
          icon: "/zf_xwdt.png",
          router: "",
        },
        {
          name: "四化政策",
          icon: "/zf_shzc.png",
          router: "",
        },
        {
          name: "名单公示",
          icon: "/zf_mdgs.png",
          router: "",
        },
        {
          name: "行业解决方案",
          icon: "/zf_jjfa.png",
          router: "",
        },
        {
          name: "优秀案例",
          icon: "/zf_yxal.png",
          router: "",
        },
        {
          name: "课程发布",
          icon: "/zf_kcfb.png",
          router: "/government/course",
        },
        {
          name: "审批中心",
          icon: "/zf_spzx.png",
          router: "/government/approval",
        },
      ],
    };
  },
  created() {
    this.userId = JSON.parse(sessionStorage.getItem("user")).id;

    this.is_stu = sessionStorage.getItem("is_stu");

    this.activeIndex = sessionStorage.getItem("menuIndex");
    if (this.is_stu == 1 && this.userId == 275) {
      this.meanList = this.SZHZFMeanList;
      this.getnum(this.is_stu);
    } else if (this.is_stu == 1 && this.userId != 275) {
      this.meanList = this.ZFMeanList;
      this.getnum(this.is_stu);
    } else if (this.is_stu == 2) {
      this.meanList = this.PTSMeanList;
      this.getnum(this.is_stu);
      // 临时修改 2  - 增加id为252的判断
      // } else if (this.is_stu == 3 && this.userId != 252) {
    } else if (this.is_stu == 3) {
      this.meanList = this.QYMeanList;
      // } else if (this.is_stu == 3 && this.userId == 252) {
      //   this.meanList = this.WJMeanList;
    }
    // 临时修改 2 结束
    else if (this.is_stu == 4) {
      this.meanList = this.SonPTSMeanList;
    }
    this._GetTrade();
  },
  methods: {
    meanChange(p, index) {
      // TODO o_0'''
      if (this.activeIndex != index) {
        // if (this.meanList[index].name == "申报征集" && this.is_stu != 1) {
        //   this.$message.warning("系统升级！");
        //   return false;
        // }
        this.activeIndex = index;
        // 如果有router并且不是政府端
        if (this.meanList[index].router && this.is_stu != 1) {
          // this.$router.push({path: p.router})
          this.$router.push({ name: p.alia });
          // this.$router.push({ path: this.meanList[index].router });
        }
        // 政府端执行下面的
        sessionStorage.setItem("menuIndex", index);
        if (this.is_stu == 1) {
          if (
            this.meanList[index].name == "课程发布" ||
            this.meanList[index].name == "审批中心" ||
            this.meanList[index].name == "申报征集"
          ) {
            this.$router.push({ path: p.router });
            this.$store.commit("SET_NAV_NAME", this.meanList[index].name);
            sessionStorage.setItem("navName", this.meanList[index].name);
            // if (sessionStorage.getItem("navName") == "课程发布") {
            //   this.$router.push({ path: "/government/course" });
            // } else if (sessionStorage.getItem("navName") == "审批中心") {
            //   this.$router.push({ path: "/government/approval" });
            // } else {
            // }
          } else {
            this.$router.push({ path: "/government/index" });
            this.$store.commit("SET_NAV_NAME", this.meanList[index].name);
            sessionStorage.setItem("navName", this.meanList[index].name);
          }
        }
        if (
          this.meanList[index].name != "待我审批" &&
          this.meanList[index].name != "审批中心"
        ) {
          this.getnum(this.is_stu);
        }
      }
    },
    // 获取待审批数量
    getnum(num) {
      if (num == 1) {
        Get_zf_Count().then((res) => {
          this.spnum = res.data;
        });
      }
      if (num == 2) {
        Get_pts_Count().then((res) => {
          this.spnum = res.data;
        });
      }
    },
    // 获取行业
    _GetTrade() {
      GetTrade().then((res) => {
        sessionStorage.setItem("hylist", JSON.stringify(res.data));
      });
    },
  },
};
</script>
<style scoped lang="less">
@media screen and (min-width: 769px) {
  .new_z_r_top_mei .new_z_r_top_mei_img .icon {
    animation: icon-leave 0.1s steps(17) forwards;
  }

  .new_z_r_top_mei:hover .new_z_r_top_mei_img .icon {
    animation: icon-enter 0.3s steps(17) forwards;
  }
}

@keyframes icon-enter {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0 -884px;
  }
}

@keyframes icon-leave {
  0% {
    background-position: 0 -884px;
  }

  100% {
    background-position: 0 0;
  }
}

.Meannav {
  // width: 100% !important;
  .new_z_r_top_l {
    // width: 100% !important;
    height: 98px;
    box-shadow: 0px 0px 15px #80808075;
  }

  .new_z_r_top_mei_bj {
    background-color: #4a6de7;
    border-radius: 15px;

    .new_z_r_top_mei_h2 {
      color: #fff;
    }
  }

  .new_z_r_content {
    min-width: 84%;
    // min-width: 1408px;
    margin: 0 auto;

    .new_z_r_top_mei {
      color: #333;
      padding: 8px 10px 8px 10px;
      position: relative;

      .new_z_r_top_mei_img {
        .icon {
          width: 52px;
          height: 52px;
          background-size: 100% auto;
          background-position: 0 0;
          background-repeat: no-repeat;
        }
      }

      .new_z_r_top_mei_h2 {
        margin-left: 8px;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  #shitN {
    position: absolute;
    top: 10%;
    right: 0%;
    font-size: 12px;
    /*font-weight: 600;*/
    line-height: 12px;
    /*text-align: center;*/
    /*letter-spacing: 0em;*/
    /*color: red;*/
    /*border-radius: 50%;*/
    /*padding: 5px;*/
    /*background: red;*/
    /*color: #fff;*/
    display: flex;
  }

  #shit_NN,
  #shit_NNC {
    background: red;
    color: #fff;
  }

  .left-circle {
    width: 10px;
    height: 12px;
    border-radius: 50% 0 0 50%;
    background-color: red;
  }

  .right-circle {
    width: 10px;
    height: 12px;
    border-radius: 0 50% 50% 0;
    background-color: red;
  }
}
</style>
