<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="getSZHQuestionResult"
        >
          <img class="add-btn-icon" :src="'/watchresult.png' | staticMedia" />
          <span class="add-btn-title">查看问卷结果</span>
        </div>
        <div
          class="add-btn-body pointer flex column center"
          style="margin-top: 30px;"
          @click="lookAtExplanation"
        >
          <img class="add-btn-icon" :src="'/watchresult.png' | staticMedia" />
          <span class="add-btn-title">相关名词解释</span>
        </div>
      </div>
      <div class="question-list">
        <div
          class="question-header flex column horizontally"
          :style="questionHeaderImg"
        >
          <!-- <h3 class="question-header-title">{{ title }}</h3> -->
          <h3 class="question-header-title">中小企业数字化水平自评测</h3>
          <div class="question-header-tips">
            《中小企业数字化水平评测指标（2024年版）》从数字化基础、经营、管理、成效四个维度综合评估。其中，数字化基础、管理和成效三个维度采用评分的方式确定等级，数字化经营部分用场景等级判定的方式确定等级。依据数字化基础、管理及成效评测得分和数字化经营应用场景等级判定（须同时满足两部分要求），将中小企业数字化水平划分为四个等级：一级（初始级）、二级（规范级）、三级（集成级）、四级（协同级）。
          </div>
        </div>
        <div class="question-form">
          <h3 class="question-form-title">企业基本情况</h3>
          <el-form
            ref="infoForm"
            :model="infoForm"
            label-width="177px"
            label-position="left"
            size="mini"
          >
            <el-form-item label="企业名称" prop="qi_name" class="is-required">
              <el-input
                v-model="infoForm.qi_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业地址">
              <el-input
                v-model="infoForm.qi_address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item class="is-required" label="所属行业大类" prop="hy1">
              <el-select
                v-model="infoForm.hy1"
                placeholder="请选择"
                id="selectTrade"
                @change="getWjTrade_xf"
                clearable
              >
                <el-option
                  v-for="item in tradeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="is-required" label="细分行业" prop="hy2">
              <el-select
                v-model="infoForm.hy2"
                placeholder="请选择"
                no-data-text="请先选择所属行业大类"
                clearable
              >
                <el-option
                  v-for="item in trade_xf_Options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="企业联系人"
              prop="qi_username"
              class="is-required"
            >
              <el-input
                v-model="infoForm.qi_username"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="qi_telephone" class="is-required">
              <el-input
                v-model="infoForm.qi_telephone"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="上年度企业营业收入"
              prop="sys"
              class="is-required"
            >
              <div class="question-form-item flex vertical">
                <el-input
                  v-model="infoForm.sys"
                  placeholder="请输入"
                ></el-input>
                <span class="question-form-item-unit">万元</span>
              </div>
            </el-form-item>
            <el-form-item
              label="上年末企业员工人数"
              prop="srs"
              class="is-required"
            >
              <div class="question-form-item flex vertical">
                <el-input
                  v-model="infoForm.srs"
                  placeholder="请输入"
                ></el-input>
                <span class="question-form-item-unit">人</span>
              </div>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业是否为专精特新企业"
              prop="is_new"
            >
              <el-radio-group v-model="infoForm.is_new">
                <el-radio label="1" selected>是</el-radio>
                <el-radio label="0">否(如选否，则直接跳到“企业简介”)</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业专精特新等级"
              prop="is_new_level"
              v-show="infoForm.is_new == '1'"
            >
              <el-radio-group v-model="infoForm.is_new_level">
                <el-radio label="国家专精特新小巨人"></el-radio>
                <el-radio label="省级专精特新"></el-radio>
                <el-radio label="市级专精特新"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业是否曾经获得过工信部专精特新相关奖补资金"
              prop="is_new_money"
              v-show="infoForm.is_new == '1'"
            >
              <el-radio-group v-model="infoForm.is_new_money">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="工信部专精特新相关奖补资金"
              prop="new_money"
              v-show="infoForm.is_new_money == '1' && infoForm.is_new == '1'"
            >
              <div class="question-form-item flex vertical">
                <el-input
                  v-model="infoForm.new_money"
                  placeholder="请填写资金金额"
                ></el-input>
                <span class="question-form-item-unit">元</span>
              </div>
            </el-form-item>
            <el-form-item label="企业简介">
              <el-input
                v-model="infoForm.introduction"
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              class="is-required"
              label="企业是否有数字化项目落地意愿"
              prop="new_project"
            >
              <el-radio-group v-model="infoForm.new_project">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业数字化建设的主要需求" prop="new_need_info">
              <el-input
                v-model="infoForm.new_need_info"
                type="textarea"
                :rows="6"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="是否涉及数字化车间或智能工厂" prop="is_has">
              <el-radio-group v-model="infoForm.is_has">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <el-form
            ref="questionForm"
            label-width="40px"
            size="mini"
            class="question-form2"
          >
            <div v-for="(item, index) in formList" :key="index">
              <h4 class="question-form2-h4 first">
                {{ index + 1 }}、{{ item.name }}
              </h4>
              <template v-if="item.selftQuestionStemList.length > 0">
                <div
                  v-for="(sItem, sIndex) in item.selftQuestionStemList"
                  :key="index + '.' + sIndex"
                  class="pl24"
                >
                  <h5 class="question-form2-h5 second">
                    {{ index + 1 }}.{{ sIndex + 1 }} {{ sItem.name }}
                  </h5>
                  <!--单选题-->
                  <el-form-item
                    v-if="sItem.multiple == 2 && sItem.selftQuestionStemDetails"
                  >
                    <el-radio-group v-model="sItem.answer" class="mt5">
                      <el-radio
                        v-for="(answer,
                        aIndex) in sItem.selftQuestionStemDetails"
                        :key="aIndex"
                        :label="answer.id"
                      >
                        {{ answer.chose + ". " + answer.choseDetail }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <!--多选题-->
                  <!-- {{ sItem.selftQuestionStemDetails }} -->
                  <el-form-item
                    v-if="sItem.multiple == 1 && sItem.selftQuestionStemDetails"
                  >
                    <el-checkbox-group v-model="sItem.answer">
                      <el-checkbox
                        v-for="(answer,
                        aIndex) in sItem.selftQuestionStemDetails"
                        :key="aIndex"
                        :label="answer.id"
                      >
                        {{ answer.chose + ". " + answer.choseDetail }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <!-- 填空题 -->
                  <el-form-item
                    v-if="
                      sItem.isOne == 1 &&
                        (sItem.remark != '' || sItem.remark != null)
                    "
                  >
                    <div class="fillOptions">
                      <div
                        class="fillOption"
                        v-for="(item, index) in sItem.remark.split(/\[\]/)"
                        :key="index"
                      >
                        {{ item }}
                        <el-input
                          v-if="index < sItem.count"
                          v-model="sItem.fill[index]"
                          style="width: 128px;"
                        />
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </template>
            </div>
            <div class="question-form2-btns flex horizontally">
              <el-button class="primaryBtn1 btn" @click="saveDraft"
                >保存草稿</el-button
              >
              <el-button class="primaryBtn1 btn" @click="getDraft"
                >获取草稿</el-button
              >
              <el-button class="primaryBtn1 btn" @click="handleSubmit"
                >下一步</el-button
              >
              <!-- <el-button class="cancleBtn1 btn" @click="handleCancle"
                >取消</el-button
              > -->
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
      title="选择问卷结果"
      :visible.sync="resultDialog"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="result-btn flex vertical" @click="getSZHQuestionResult">
        <img
          class="result-btn-img"
          :src="'/szh_wj_nav.png' | staticMedia"
          alt=""
        />
        中小企业数字化水平自评测
      </div>
    </el-dialog>
    <question-result
      ref="questionResult"
      :globalData="globalData"
    ></question-result>
    <Interpretation ref="interpretation"></Interpretation>
    <!-- <el-dialog
      title="中小企业数字化水平自评测"
      :visible.sync="shuZiHuaResultDialog"
      width="788px"
      top="3vh"
      :close-on-click-modal="false"
    >
      <div class="shuzihua-result">
        <div class="shuzihua-result-img">
          <img
            class="shuzihua-result-image"
            :src="'/resultbac.png' | staticMedia"
            alt=""
          />
        </div>
        <h3 class="shuzihua-result-title">企业数字化水平诊断结果</h3>
        <h4 class="shuzihua-result-subtitle">企业数字化水平等级</h4>
        <el-table :data="levelList" border style="width: 100%">
          <el-table-column prop="score" label="数字化基础、管理及成效评分">
          </el-table-column>
          <el-table-column prop="level1" label="数字化经营应用场景等级">
          </el-table-column>
          <el-table-column prop="level2" label="测评等级"></el-table-column>
        </el-table>
        <h4 class="shuzihua-result-subtitle2">数字化基础、管理及成效测评表</h4>
        <div class="description">
          <div>
            企业数字化经营应用场景等级判定标准：
          </div>
          <div>
            一级：企业应用信息技术工具辅助开展工作，实现相关业务的效率提升。
          </div>

          <div>
            二级：企业对关键生产环节开展在线数据采集和应用，基于信息化系统实现关键业务环节的数字化、规范化管理。
          </div>
          <div>
            三级：企业应用工业互联网等数字化技术开展信息和数据的实时采集和应用，通过跨部门、跨系统数据集成共享实现主要业务流程的数字化集成。
          </div>
          <div>
            四级：企业运用人工智能等前沿技术引领转型升级，全面实现人机物互联互通，打造孪生工厂、资源智能调度、供应链可视化协同等先进制造典型应用，构建基于数据应用的模型驱动生产运营模式，持续推进产业链协同
          </div>
        </div>
      </div>
    </el-dialog> -->

    <Footer></Footer>
  </div>
</template>
<script>
import {
  getSZHQuestionList,
  getTradeOptions,
  getTradeXFOptions,
  // setSZHQuestion,
  // getSZHResult,
  getQuestionAll,
  addSelftResult,
  getSelftResult,
  createSelftDraf,
  getSelftDraft,
} from "@/api/qy";
import QuestionResult from "./components/questionResult.vue";
import Interpretation from "./components/interpretation.vue";
export default {
  components: { QuestionResult, Interpretation },

  // 使用provide属性将全局数据对象注入到组件实例
  //  provide() {
  //   return {
  //     globalData: this.globalData
  //   }
  // },
  data() {
    return {
      //传入组件的数据
      globalData: {
        // 要传递给子组件的数据
        // someData: '',
      },

      // Dialog
      resultDialog: false,
      shuZiHuaResultDialog: false,

      title: "中小企业数字化水平自评测",

      // 企业信息表格（非问卷
      infoForm: {
        qi_name: "",
        qi_address: "",
        hy1: "",
        hy2: "",
        qi_username: "",
        qi_telephone: "",
        sys: "",
        srs: "",
        is_new: "",
        is_new_level: "",
        is_new_money: "",
        new_money: "",
        introduction: "",
        new_project: "",
        new_need_info: "",
        is_has: "",
      },
      formList: [],
      formListResult: [],
      // 行业选项列表
      tradeOptions: [],
      // 细分行业选项列表
      trade_xf_Options: [],
      // 问卷结果表格数据
      tipList: [
        {
          name: "数字化成效",
          statistical: "1.00",
        },
        {
          name: "数字化管理",
          statistical: "1.10",
        },
        {
          name: "数字化经营",
          statistical: "2.10",
        },
      ],
      // 问卷结果表格数据
      dataList: {
        total: "200.00",
        level: "1",
      },
      option: {
        tooltip: {
          show: true,
        },
        radar: [
          {
            indicator: [
              { text: "基础要素", color: "#205dc3", min: 0, max: 10 },
              { text: "绿色化提升", color: "#205dc3", min: 0, max: 10 },
              { text: "智能化制造", color: "#205dc3", min: 0, max: 10 },
              { text: "网络化协同", color: "#205dc3", min: 0, max: 10 },
              { text: "数字化转型", color: "#205dc3", min: 0, max: 10 },
            ],
            center: ["50%", "50%"],
            radius: 215,
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            scale: true,
            axisName: {
              formatter: "{value}",
              color: "#205dc3",
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["#64AFE945", "#64AFE990", "#64AFE9", "#428BD4"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            lineStyle: {
              width: 4,
            },
            emphasis: {
              lineStyle: {
                width: 5,
              },
            },
            data: [
              {
                value: [10, 20, 10, 10, 10],
                name: "",
              },
            ],
          },
        ],
      },
      answer_: [],
      levelList: [
        {
          score: 0,
          level1: 1,
          level2: 2,
        },
      ],
    };
  },
  computed: {
    questionHeaderImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/text_table_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  created() {},
  mounted() {
    // this.getQuestionList();
    this.getTradeOptions();
    this.getQuestionAll();
  },
  methods: {
    lookAtExplanation() {
      this.$refs.interpretation.onShow();
    },
    saveDraft() {
      let _this = this;
      let content = {
        infoForm: _this.infoForm,
        formList: _this.formList,
      };
      let pdata = {
        content: JSON.stringify(content),
        questionId: 1,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      createSelftDraf(pdata).then((res) => {
        console.log("res", res);
        if (res.code == 200) {
          this.$message.success("保存成功");
        } else {
          this.$message.error("保存失败");
        }
      });
    },
    getDraft() {
      let pdata = {
        questionId: 1,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      getSelftDraft(pdata).then((res) => {
        if (res.code == 200) {
          this.$message.success("获取成功");
          this.infoForm = JSON.parse(res.data.content).infoForm;
          this.formList = JSON.parse(res.data.content).formList;
        } else {
          this.$message.error("获取失败");
        }
      });
    },
    handleResultAnswer(list) {
      list.forEach((el) => {
        if (el.selftQuestionStemList && el.selftQuestionStemList.length) {
          el.selftQuestionStemList.forEach((e) => {
            if (e.multiple == 2) {
              e.answer = e.selectedOptionIds[0];
            } else {
              e.answer = e.selectedOptionIds;
            }
            e.fill = e.fillOptions;
          });
        }
      });
      return list;
    },
    getSZHQuestionResult() {
      let _this = this;
      // _this.$refs.questionResult.isShowDialog = true;
      _this.$refs.questionResult.getSZHQuestionResult();
      _this.resultDialog = false;
      // let pdata = {
      //   questionId: 1,
      //   userId: JSON.parse(sessionStorage.getItem("user")).id,
      // };
      // getSelftResult(pdata)
      //   .then((res) => {
      //     console.log("res设计", res);
      //     this.globalData.someData = res.data;
      //     // this.formListResult = _this.handleResultAnswer(
      //     //   res.data.selftQuestionAllDto.selftQuestionDetailDtoList
      //     // );
      //   })
      //   .finally(() => {
      //     // _this.shuZiHuaResultDialog = true;

      //   });

      // getSZHResult().then((res) => {
      //   console.log("res", res);
      //   _this.dataList = res.data;
      //   _this.tipList = res.data.data;
      //   _this.zdScoreList = res.data.data2;

      //   let ayy = [];
      //   let sna = [];

      //   // 默认最大为35
      //   let maxScore = 0;
      //   _this.tipList.forEach((item) => {
      //     if (item.name != "其他相关信息") {
      //       if (maxScore < Number(item.statistical)) {
      //         maxScore = item.statistical;
      //       }
      //     }
      //   });

      //   _this.tipList.forEach((item) => {
      //     console.log(item);
      //     if (item.name != "其他相关信息") {
      //       let obj = {
      //         text: item.name,
      //         color: "#205dc3",
      //         min: 0,
      //         max: maxScore,
      //       };
      //       ayy.push(obj);
      //       sna.push(item.statistical);
      //     }
      //   });

      //   _this.option.radar[0].indicator = ayy;
      //   _this.option.series[0].data[0].value = sna;

      //   setTimeout(() => {
      //     _this.chartDom = _this.$refs.shuzihuaEcharts;
      //     _this.myChart = _this.$echarts.init(_this.chartDom);
      //     _this.myChart.setOption(_this.option);
      //   }, 500);
      // });
    },
    handleSubmit() {
      var _this = this;
      let loading = _this.$loading({
        lock: true,
        text: "提交中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const answer = _this.getAnswer();

      var form = _this.infoForm;
      // 临时修改 2
      if (form.qi_name == "" || form.qi_name == null) {
        _this.$message({
          message: "请输入企业名称！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.hy1 == "" || form.hy1 == null) {
        _this.$message({
          message: "请选择所属行业大类！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.hy2 == "" || form.hy2 == null) {
        _this.$message({
          message: "请选择细分行业！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.qi_username == "" || form.qi_username == null) {
        _this.$message({
          message: "请输入企业联系人！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.qi_telephone == "" || form.qi_telephone == null) {
        _this.$message({
          message: "请输入电话！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.qi_telephone.length !== 11) {
        _this.$message({
          message: "请输入正确的手机号码！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.sys == "" || form.sys == null) {
        _this.$message({
          message: "请输入上年度企业营业收入！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.srs == "" || form.srs == null) {
        _this.$message({
          message: "请输入上年末企业员工人数！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.is_new == "" || form.is_new == null) {
        _this.$message({
          message: "请选择企业是否为专精特新企业！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (
        form.is_new == 1 &&
        (form.is_new_level == "" || form.is_new_level == null)
      ) {
        _this.$message({
          message: "请选择企业专精特新等级！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (
        form.is_new == 1 &&
        (form.is_new_money == "" || form.is_new_money == null)
      ) {
        _this.$message({
          message: "请选择企业是否曾经获得过工信部专精特新相关奖补资金！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (
        form.is_new == 1 &&
        form.is_new_money == 1 &&
        (form.new_money == "" || form.new_money == null)
      ) {
        _this.$message({
          message: "请输入工信部专精特新相关奖补资金！",
          type: "warning",
        });
        loading.close();
        return false;
      }
      if (form.new_project == "" || form.new_project == null) {
        _this.$message({
          message: "请选择企业是否有数字化项目落地意愿！",
          type: "warning",
        });
        loading.close();
        return false;
      }

      let new_data = {
        hy1: "" + form.hy1 || "",
        hy2: "" + form.hy2 || "",
        is_new_level: form.is_new_level || "",
        is_new_money: form.is_new_money || "",
        new_money: form.new_money || "",
        introduction: form.introduction || "",
        new_project: form.new_project || "",
        new_need_info: form.new_need_info || "",
        is_has: form.is_has || "",
      };
      let userInfo = {
        qi_name: form.qi_name || "",
        qi_username: form.qi_username || "",
        qi_telephone: form.qi_telephone || "",
        qi_address: form.qi_address || "",
        trade_id: "" + form.hy1 || "",
        sshy: "" + form.hy2 || [],
        sys: form.sys || "",
        srs: form.srs || "",
        is_new: form.is_new || "",
      };
      let pdata = {
        userInfo: JSON.stringify(userInfo),
        newData: JSON.stringify(new_data),
        content: JSON.stringify(answer),
        questionId: 1,
        type: 1,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      addSelftResult(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.code == 200) {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.$router.push({
                path: "/selfAssessmentQuestionSec",
              });
            }, 2000);
          } else {
            this.$message.error("提交失败");
          }
        })
        .catch((err) => {
          console.log("add err", err);

          this.$message.error("提交失败");
        })
        .finally(() => {
          loading.close();
        });
    },
    handleCancle() {},
    openResultDialog() {
      this.resultDialog = true;
    },
    getTradeOptions() {
      let pdata = {
        pid: 0,
      };
      getTradeOptions(pdata).then((res) => {
        console.log("res", res);
        this.tradeOptions = res.data.map((e) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
      });
    },
    getWjTrade_xf(id) {
      console.log("123", id);
      let pdata = {
        pid: id,
      };
      getTradeXFOptions(pdata).then((res) => {
        console.log("res", res);
        this.trade_xf_Options = res.data.map((e) => {
          return {
            id: e.id,
            name: e.name,
          };
        });
      });
    },
    handleAnswer(list) {
      list.forEach((el) => {
        if (el.selftQuestionStemList && el.selftQuestionStemList.length) {
          el.selftQuestionStemList.forEach((e) => {
            // if (e.multiple == 2) {
            e.answer = [];
            e.fill = [];
            // }
          });
        }
      });
      return list;
    },
    getQuestionAll() {
      let _this = this;
      getQuestionAll({
        questionId: 1,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      })
        .then((res) => {
          // console.log("res", res, _this);
          _this.infoForm = {
            ...JSON.parse(res.data.newData),
            ...JSON.parse(res.data.userInfo),
          };
          if (res.data.newData && JSON.parse(res.data.newData).hy1) {
            _this.getWjTrade_xf(JSON.parse(res.data.newData).hy1);
          }
          if (_this.infoForm.hy1) {
            _this.infoForm.hy1 = Number(_this.infoForm.hy1);
          }
          if (_this.infoForm.hy2) {
            _this.infoForm.hy2 = Number(_this.infoForm.hy2);
          }
          if (
            res.data.selftQuestionDetailDtoList[0].selftQuestionStemList[0]
              .selectedOptionIds &&
            res.data.selftQuestionDetailDtoList[0].selftQuestionStemList[0]
              .selectedOptionIds.length > 0
          ) {
            _this.formList = _this.handleResultAnswer(
              res.data.selftQuestionDetailDtoList
            );
            // this.getQuestionList();
          } else {
            _this.formList = _this.handleAnswer(
              res.data.selftQuestionDetailDtoList
            );
          }
        })
        .finally(() => {});
    },
    getQuestionList() {
      let _this = this;
      getSZHQuestionList()
        .then((res) => {
          console.log("res", res);
          // _this.formList = _this.handleAnswer(res.data.p_cate);
          _this.infoForm.qi_name = res.data.name;
          _this.infoForm.qi_username = res.data.truename;
          _this.infoForm.qi_telephone = res.data.mobile;
          _this.infoForm.qi_address = `${res.data.province}${res.data.city}${res.data.area}`;
        })
        .finally(() => {});
    },
    getAnswer() {
      var answer = [];
      console.log("this.formlist", this.formList);

      this.formList.forEach(function(el) {
        if (el.selftQuestionStemList && el.selftQuestionStemList.length) {
          el.selftQuestionStemList.forEach(function(e) {
            if (
              e.selftQuestionStemDetails &&
              e.selftQuestionStemDetails.length
            ) {
              let obj = {
                questionDetailId: null,
                questionStemId: null,
                selectedOptionIds: [],
              };
              obj.questionDetailId = e.questionDetailId;
              obj.questionStemId = e.selftQuestionStemDetails[0].questionStemId;
              if (e.answer instanceof Array) {
                obj.selectedOptionIds = e.answer;
              } else {
                obj.selectedOptionIds = [e.answer];
              }
              console.log("e.fill", e.fill);

              obj.fillOptions = e.fill;
              answer.push(obj);
            }
          });
        }
      });

      return answer;
    },
  },
};
</script>
<style lang="less" scoped>
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .question-list {
    width: 84%;
    margin: 0 auto;
    .question-header {
      width: 100%;
      height: 300px;
      background-size: 100% 100%;
      color: #fff;
      padding: 0 2% 0 32%;
      margin-bottom: 20px;
      box-sizing: border-box;
      .question-header-title {
        font-size: 24px;
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
      }

      .question-header-tips {
        line-height: 32px;
        font-size: 15px;
      }
    }
    .question-form {
      background-color: #fff;
      padding: 40px 200px;
      border-radius: 10px;
      .question-form-title {
        position: relative;
        margin-bottom: 88px;
        font-size: 24px;
        text-align: center;
        &::before {
          content: "";
          width: 300px;
          height: 4px;
          position: absolute;
          display: inline-block;
          width: 250px;
          height: 4px;
          background-color: #205dc3;
          bottom: -10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          border-radius: 3px;
        }
      }
      .question-form-item {
        .question-form-item-unit {
          display: inline-block;
          width: 35px;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          color: #606266;
        }
      }
      .el-form {
        width: 84%;
      }
      .question-form2 {
        .question-form2-h4,
        .question-form2-h5 {
          font-size: 14px;
          color: #606266;
        }
        .first {
          padding: 8px 0;
        }
        .second {
          padding: 5px 0;
          text-indent: 2rem;
        }
        .third {
          padding: 5px 0;
          text-indent: 4rem;
          line-height: 1.5;
        }
        .el-form-item--mini.el-form-item {
          padding: 0 2rem;
        }
        .el-radio {
          margin-bottom: 10px;
        }
        .el-checkbox {
          display: flex;
          white-space: pre-wrap;
          .el-checkbox__input {
            padding-top: 3px;
          }
        }
      }
    }
  }
}
.result-btn {
  width: fit-content;
  height: 80px;
  font-size: 18px;
  color: #333;
  padding: 0 10px;
  &:hover {
    color: #fff;
    background-color: #205dc3;
    border-radius: 10px;
  }
  .result-btn-img {
    width: 46px;
    height: 46px;
    margin-right: 10px;
  }
}
.shuzihua-result {
  padding: 0 50px 10px;
  .description {
    line-height: 1.5;
  }
  .shuzihua-result-title {
    height: 42px;
    line-height: 42px;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .shuzihua-result-subtitle {
    height: 32px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;
  }
  .shuzihua-result-image {
    width: 100%;
  }
  .shuzihua-part {
    padding: 10px 0;
    .shuzihua-part-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      .blue-point {
        width: 10px;
        height: 10px;
        background-color: #205dc3;
        border-radius: 10px;
        margin: 0 5px;
      }
    }
    .shuzihua-part-text {
      line-height: 1.5;
      margin-top: 10px;
      margin-left: 20px;
      color: #333;
      .shuzihua-part-text-weight {
        color: #333;
        font-weight: 600;
      }
    }
    .shuzihua-part-table {
      width: 373px;
      margin: 10px auto;
      border: 1px solid #d8d8d8;
      .shuzihua-part-table-title {
        .shuzihua-table-title-item {
          width: 186px;
          height: 46px;
          line-height: 46px;
          background-color: #205dc3;
          text-align: center;
          color: #fff;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
      .shuzihua-part-table-list {
        &:last-child {
          .shuzihua-table-list-item {
            border-bottom: none;
          }
        }
        .shuzihua-table-list-item {
          width: 186px;
          height: 56px;
          line-height: 56px;
          color: #333;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .shuzihua-echarts {
      width: 600px;
      height: 500px;
      margin: 10px auto;
    }
  }
}
.fillOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .fillOption {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
    &:hover {
      border: none;
      border-bottom: 1px solid #c0c4cc;
      border-radius: 0;
    }
  }
}

.el-radio-group {
  width: 100%;
  .el-radio {
    text-wrap: wrap;
  }
}
</style>
