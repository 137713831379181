<template>
  <div class="main">
    <div class="container">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item
          :to="{
            path: '/enterprise/sbzj',
          }"
          >申报征集</el-breadcrumb-item
        >
        <el-breadcrumb-item> 试点企业改造意向摸查</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="verify-table">
        <template>
          <el-table
            :data="tableData"
            border
            style="width: 100%;margin-top: 20px;"
            :key="itemKey"
          >
            <el-table-column type="index" label="序号" width="55" key="qy_序号">
            </el-table-column>
            <el-table-column
              prop="enterpriseName"
              label="公司名称"
              show-overflow-tooltip
              key="qy_公司名称"
            >
            </el-table-column>
            <el-table-column
              prop="administrativeRegion"
              label="所属行政区"
              show-overflow-tooltip
              key="qy_所属行政区"
            >
            </el-table-column>
            <el-table-column
              prop="contactPerson"
              label="联系人"
              show-overflow-tooltip
              key="qy_联系人"
            >
            </el-table-column>
            <el-table-column
              prop="contactPhone"
              label="联系方式"
              show-overflow-tooltip
              key="qy_联系方式"
            >
            </el-table-column>
            <el-table-column
              prop="subIndustry"
              label="所属细分行业"
              show-overflow-tooltip
              key="qy_所属细分行业"
            >
            </el-table-column>
            <el-table-column
              prop="smallerMediumEnterprise"
              label="是否为中小企业"
              key="qy_是否为中小企业"
            ></el-table-column>
            <el-table-column
              prop="auditStatus"
              label="备案状态"
              key="qy_审批状态"
            >
              <template slot-scope="scope">
                <div v-if="!scope.row.auditReason">
                  <span v-if="scope.row.auditStatus == 1">
                    审核中
                  </span>
                  <span v-if="scope.row.auditStatus == 2">
                    审核通过
                  </span>
                  <span v-if="scope.row.auditStatus == 3">
                    审核不通过
                  </span>
                </div>

                <el-tooltip
                  effect="dark"
                  :content="scope.row.auditReason"
                  placement="top-start"
                  v-else
                >
                  <span v-if="scope.row.auditStatus == 1">
                    审核中
                  </span>
                  <span v-if="scope.row.auditStatus == 2">
                    审核通过
                  </span>
                  <span v-if="scope.row.auditStatus == 3">
                    审核不通过
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="128" key="qy_操作">
              <template slot-scope="scope">
                <el-button type="text" @click="handleQYDetail(scope.row, 2)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="page"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// import configs from "@/config/index";
import { formatDateTime } from "@/utils/common";
import { getCompanyInfo, getDigitalReadyDetail } from "@/api/qy";
export default {
  name: "ApprovalCenter",
  data() {
    return {
      creditCode: "", //统一社会信用代码
      itemKey: "",
      // 已审批企业表格
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  created() {},
  mounted() {
    this.getCompanyInfoMethod();
  },
  methods: {
    //跳转问卷详情
    handleQYDetail(row, type) {
      this.$router.push({
        name: "declareForm",
        query: {
          type: type,
          id: row.id,
          creditCode: this.creditCode,
        },
      });
    },

    getCompanyInfoMethod() {
      getCompanyInfo().then((res) => {
        // console.log(res, "getCompanyInfoMethod");

        if (res.code == 1) {
          this.creditCode = res.data.jgm;
          let pdata = {
            creditCode: res.data.jgm,
            type: "1",
          };
          getDigitalReadyDetail(pdata).then((res1) => {
            console.log(res1, "getDigitalReadyDetail");
            if (res1.code == 200) {
              if (!res1.data || !res1.data.id) return false;
              this.tableData.push(res1.data);
            }
          });
        }
      });
    },

    // 分页器pagechange
    handleCurrentChange(page) {
      this.page = page;
    },
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-pagination {
  width: 100%;
  margin: 10px auto;
  text-align: right;
  box-sizing: border-box;
}
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
::v-deep .el-tabs__item {
  color: #666;
  font-size: 22px;
  margin-bottom: 8px;
  &.is-active {
    color: #333;
    font-weight: 600;
  }
  &:not(.is-active):hover {
    color: #666;
  }
}
::v-deep .el-tabs__active-bar {
  height: 3px;
  // margin-top: 8px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #d8d8d8;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  color: #333;
  background-color: #eef2ff;
  border-color: #d8d8d8;
}
::v-deep .el-table--border {
  border-color: #d8d8d8;
}
.main {
  .container {
    width: 84%;
    margin: 0 auto;
    padding: 30px 25px 0;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.approval-main {
  border-left: 2px solid #205dc3;
  margin: 20px 50px;

  .approval-list {
    padding-left: 50px;
    .approval-item {
      .approval-title {
        position: relative;

        color: #666;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 20px;
        margin-top: 20px;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 18px;
          background-color: #205dc3;

          position: absolute;
          left: -59px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
      .approval-body {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: flex-start;
        .body-status {
          display: inline-block;
          // width: 150px;
          line-height: 1;
          padding: 3px 8px;
          text-align: center;
          border-radius: 3px;
          font-size: 12px;
          margin: 6px 0 0 15px;
          &.wait {
            color: #666;
            border: 1px solid #666;
          }
          &.pass {
            color: #67c23a;
            border: 1px solid #67c23a;
          }
          &.reject {
            color: #f56c6c;
            border: 1px solid #f56c6c;
          }
        }
        .body-time {
          margin-left: 15px;
          font-size: 12px;
          color: #666;
        }
        .body-person {
          display: inline-block;
          height: fit-content;
          text-align: left;
          color: #205dc3;
          border-radius: 3px;
          line-height: 2;
          max-width: 54%;
          &.no {
            cursor: default;
            color: #999;
          }
        }

        .choose-btn {
          margin-left: 15px;
        }
      }

      &:last-child {
        .approval-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-body {
  // padding: 25px 30px;
  overflow-x: auto;
  .content-body-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    .content-body-content {
      width: calc(100% - 65px);
      .content-html {
        img {
          width: 100% !important;
        }
      }
      img {
        max-width: 100%;
        width: fit-content;
        border-radius: 5px;
      }
    }
    .content-body-title {
      width: 65px;
      color: #666666;
    }
  }
}
::v-deep .verifyForm {
  .rejectTips {
    font-size: 18px;
    padding: 10px 0;
  }
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-form--inline .el-form-item__content,
  .el-form--inline .el-form-item {
    width: 100%;
  }
}

::v-deep .btn-content {
  // margin: 0 auto;
  // display: flex;
  // justify-content: center;
  .el-button {
    margin: 0;
  }
}
</style>
