<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="same-container">
        <div class="size-container">
          <div class="public-container">
            <div class="top-nav">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item
                  :to="{
                    path: '/enterprise/sbzj',
                  }"
                  >申报征集</el-breadcrumb-item
                >
                <el-breadcrumb-item
                  v-if="pageType !== 1"
                  :to="{
                    path: '/enterprise/sbzj/list2',
                  }"
                  >数字化改造项目备案</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="pageType === 1"
                  >申报</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="pageType === 2"
                  >详情</el-breadcrumb-item
                >
                <el-breadcrumb-item v-if="pageType === 3"
                  >编辑</el-breadcrumb-item
                >
              </el-breadcrumb>
            </div>
            <div class="form-container">
              <div class="form-title-content">
                <h1 class="form-title">
                  数字化改造项目备案申报
                </h1>
                <div class="line"></div>
              </div>
              <el-form
                label-position="left"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="250px"
                class="ruleForm"
              >
                <h5 class="subheadings">一、企业基本信息</h5>
                <el-form-item label="企业名称" prop="companyName">
                  <el-input
                    v-model="ruleForm.companyName"
                    disabled
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="法人代表" prop="baseInfo.frdb">
                  <el-input
                    v-model="ruleForm.baseInfo.frdb"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="所属城市" prop="baseInfo.sscs">
                  <el-input
                    v-model="ruleForm.baseInfo.sscs"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="所属区" prop="baseInfo.ssq">
                  <el-input
                    v-model="ruleForm.baseInfo.ssq"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="baseInfo.zcdz">
                  <el-input
                    v-model="ruleForm.baseInfo.zcdz"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="统一社会信用代码" prop="baseInfo.tyshxydm">
                  <el-input
                    v-model="ruleForm.baseInfo.tyshxydm"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="baseInfo.lxr">
                  <el-input
                    v-model="ruleForm.baseInfo.lxr"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="baseInfo.lxfs">
                  <el-input
                    v-model="ruleForm.baseInfo.lxfs"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="企业基本情况" prop="companyInfo">
                  <el-input
                    v-model="ruleForm.companyInfo"
                    :disabled="isDetail"
                    type="textarea"
                    placeholder="请输入（企业主导产品及应用领域、现有数字化基础、企业荣誉资质等，字数300以内）"
                    :autosize="{ minRows: 6 }"
                    maxlength="300"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <!-- label="是否为制造业企业" -->
                <el-form-item label="企业性质" prop="companyQuality">
                  <div class="sf-content">
                    <el-radio-group
                      v-model="ruleForm.companyQuality"
                      size="medium"
                      :disabled="isDetail"
                    >
                      <el-radio label="国有"></el-radio>
                      <el-radio label="民营"></el-radio>
                      <el-radio label="外资"></el-radio>
                      <el-radio label="混合所有制"></el-radio>
                      <el-radio label="其他"></el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item prop="companySize">
                  <span slot="label">
                    企业规模一
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="（中小企业规模类型自测：https://baosong.miit.gov.cn/ScaleTest）"
                      placement="top-start"
                    >
                      <i class="el-icon-warning"></i>
                    </el-tooltip>
                  </span>
                  <el-radio-group
                    v-model="ruleForm.companySize"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio label="中型企业"></el-radio>
                    <el-radio label="小型企业"></el-radio>
                    <el-radio label="微型企业"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业规模二" prop="companySize2">
                  <el-radio-group
                    v-model="ruleForm.companySize2"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio label="规模以上企业"></el-radio>
                    <el-radio label="规模以下企业"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="中小企业数字化水平测评等级"
                  prop="digitalLevel"
                >
                  <el-radio-group
                    v-model="ruleForm.digitalLevel"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio label="无等级"></el-radio>
                    <el-radio label="一级"></el-radio>
                    <el-radio label="二级"></el-radio>
                    <el-radio label="三级"></el-radio>
                    <el-radio label="四级"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="专精特新情况" prop="specialization">
                  <el-radio-group
                    v-model="ruleForm.specialization"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio label="无"></el-radio>
                    <el-radio label="计划申报"></el-radio>
                    <el-radio label="广东省创新型中小企业"></el-radio>
                    <el-radio label="广东省“专精特新”中小企业"></el-radio>
                    <el-radio label="专精特新“小巨人”企业"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="所属细分行业" prop="detailTrade">
                  <el-radio-group
                    v-model="ruleForm.detailTrade"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio label="智能网联与新能源汽车（零部件）"></el-radio>
                    <el-radio label="工业母机和机器人"></el-radio>
                    <el-radio label="时尚美妆"></el-radio>
                    <el-radio label="定制家居"></el-radio>
                    <el-radio label="服装"></el-radio>
                    <el-radio label="箱包"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="上年营业收入（万元）" prop="lastRevenue">
                  <el-input
                    v-model="ruleForm.lastRevenue"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="上年利润（万元）" prop="lastProfit">
                  <el-input
                    v-model="ruleForm.lastProfit"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="上年人均营业收入（万元）"
                  prop="lastCapitaRevenue"
                >
                  <el-input
                    v-model="ruleForm.lastCapitaRevenue"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="已有数字化转型投入（万元）"
                  prop="digitalInputMoney"
                >
                  <el-input
                    v-model="ruleForm.digitalInputMoney"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="企业总人数（人）" prop="companyPerson">
                  <el-input
                    v-model="ruleForm.companyPerson"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="企业数字化人员数量（人）"
                  prop="digitalPerson"
                >
                  <el-input
                    v-model="ruleForm.digitalPerson"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="form-container">
              <el-form
                label-position="left"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm1"
                label-width="250px"
                class="ruleForm"
              >
                <h5 class="subheadings">二、试点项目基本信息</h5>
                <el-form-item
                  label="项目名称"
                  prop="digitalCultivationProduct.projectName"
                >
                  <el-input
                    v-model="ruleForm.digitalCultivationProduct.projectName"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="项目实施期限"
                  prop="digitalCultivationProduct.startTime"
                >
                  <el-date-picker
                    v-model="ruleForm.digitalCultivationProduct.startTime"
                    type="date"
                    placeholder="请选择实施期限"
                    value-format="yyyy-MM-dd"
                    :disabled="isDetail"
                  >
                  </el-date-picker>
                  <span style="margin: 0 10px;">
                    至
                  </span>
                  <el-date-picker
                    v-model="ruleForm.digitalCultivationProduct.endTime"
                    type="date"
                    placeholder="请选择实施期限"
                    value-format="yyyy-MM-dd"
                    :disabled="isDetail"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item
                  label="合作数字化牵引单位"
                  prop="digitalCultivationProduct.implementUnit"
                >
                  <!-- <el-input
                    v-model="ruleForm.digitalCultivationProduct.implementUnit"
                    :disabled="isDetail"
                    type="textarea"
                    placeholder="请输入（填写已公布的牵引单位，如实施单位为牵引单位生态企业，需同时填写牵引单位和生态企业）"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                  ></el-input> -->

                  <el-checkbox-group
                    v-model="ruleForm.digitalCultivationProduct.implementUnit"
                    size="medium"
                    :disabled="isDetail"
                    @change="handleImplementUnitChange"
                    style="display: flex;flex-direction: column;"
                  >
                    <el-checkbox label="无" class="radio-item"></el-checkbox>
                    <el-checkbox
                      v-for="(item, index) in tractionUnitList"
                      :key="index"
                      :label="item.label"
                    ></el-checkbox>
                    <!-- <el-checkbox label="广州明珞装备股份有限公司"></el-checkbox>
                    <el-checkbox
                      label="格创东智（广州）科技技术有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="浪潮云洲工业互联网有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州数控设备有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="树根互联股份有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广东玛斯特智能系统有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州蜂巢互联科技有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州中浩控制技术有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州环亚化妆品科技股份有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州尚品宅配家居股份有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="欧派家居集团股份有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州鼎捷软件有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="比音勒芬服饰股份有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广东省纺织品进出口股份有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州春晓信息科技有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      label="广州盖特软件有限公司"
                      class="radio-item"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="联通(广东)产业互联网有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="杭州海康威视数字技术股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="华为云计算技术有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="广东用友软件有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="金蝶软件(中国)有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="广东丸美生物技术股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="广州赛意信息科技股份有限公司"
                    ></el-checkbox>
                    <el-checkbox
                      class="radio-item"
                      label="广东粤桨产业科技有限公司"
                    ></el-checkbox> -->
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  label="项目合同总金额（万元/不含税）"
                  prop="digitalCultivationProduct.preInput"
                >
                  <el-input
                    v-model="ruleForm.digitalCultivationProduct.preInput"
                    :disabled="isDetail"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="产品和服务信息"
                  prop="digitalCultivationServiceList"
                  class="is-required"
                >
                  <div class="service-list">
                    <div
                      class="service-item"
                      v-for="(item,
                      index) in ruleForm.digitalCultivationServiceList"
                      :key="index"
                    >
                      <div class="service-title flex vertical bewteen">
                        <div>
                          {{ "信息" + (index + 1) }}
                        </div>
                        <img
                          v-if="index != 0"
                          @click="delService(index)"
                          class="service-del"
                          src="../../../assets/images/del.png"
                          alt=""
                        />
                      </div>
                      <div class="service-content">
                        <div class="content-title">
                          提供单位
                        </div>
                        <div class="content-form">
                          <el-select
                            v-model="item.companyId"
                            placeholder="请选择"
                            filterable
                            remote
                            :remote-method="getCompanyStuList"
                            :loading="isLoadingCompanyList"
                            @change="handleCompanyChange($event, 1, item)"
                            :disabled="isDetail"
                          >
                            <el-option
                              v-for="item in companyList"
                              :key="item.userId"
                              :label="item.name"
                              :value="item.userId"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="service-content">
                        <div class="content-title">
                          产品和服务名称
                        </div>
                        <div class="content-form">
                          <el-select
                            v-model="item.productId"
                            placeholder="请选择"
                            :loading="isLoadingProductList"
                            :disabled="isDetail"
                            @change="handleProductChange($event, item)"
                          >
                            <el-option
                              v-for="item in productList"
                              :key="item.id"
                              :label="item.productName"
                              :value="item.id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="service-content">
                        <div class="content-title">
                          代码
                        </div>
                        <div class="content-form">
                          <el-input
                            v-model="item.code"
                            disabled
                            placeholder="请输入"
                          ></el-input>
                        </div>
                      </div>
                      <!-- <div class="service-content">
                        <div class="content-title">
                          提供单位
                        </div>
                        <div class="content-form">
                          <el-input
                            v-model="item.supplyUnit"
                            :disabled="isDetail"
                            placeholder="请输入"
                          ></el-input>
                        </div>
                      </div> -->
                      <div class="service-content">
                        <div class="content-title">
                          主要功能
                        </div>
                        <div class="content-form">
                          <el-input
                            v-model="item.masterFunction"
                            :disabled="isDetail"
                            placeholder="请输入"
                            type="textarea"
                            :autosize="{ minRows: 10 }"
                            maxlength="1000"
                            show-word-limit
                          ></el-input>
                        </div>
                      </div>
                      <div class="service-content">
                        <div class="content-title">
                          含税价格(元)
                        </div>
                        <div class="content-form">
                          <el-input
                            v-model="item.money"
                            :disabled="isDetail"
                            placeholder="请输入"
                          ></el-input>
                        </div>
                      </div>
                      <div class="service-content">
                        <div class="content-title">
                          部署方式
                        </div>
                        <div class="content-form">
                          <el-input
                            v-model="item.way"
                            :disabled="isDetail"
                            placeholder="请输入"
                            type="textarea"
                            :autosize="{ minRows: 10 }"
                            maxlength="500"
                            show-word-limit
                          ></el-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="service-add flex column center"
                    @click="addService"
                    :style="{ cursor: isDetail ? 'not-allowed' : 'pointer' }"
                  >
                    <img :src="'/additem2.png' | staticMedia" />
                    <div class="text">添加更多数据</div>
                  </div>
                </el-form-item>
                <el-form-item
                  label="产品和服务类别（可多选）"
                  prop="digitalCultivationProduct.serviceCategory"
                >
                  <el-checkbox-group
                    v-model="ruleForm.digitalCultivationProduct.serviceCategory"
                    :disabled="isDetail"
                  >
                    <el-checkbox label="数字化咨询服务"></el-checkbox>
                    <el-checkbox label="网络通信服务"></el-checkbox>
                    <el-checkbox label="软件和模型开发"></el-checkbox>
                    <el-checkbox label="数据分析服务"></el-checkbox>
                    <el-checkbox label="系统集成服务"></el-checkbox>
                    <el-checkbox label="云服务和云应用"></el-checkbox>
                    <el-checkbox label="安全防护服务"></el-checkbox>
                    <el-checkbox label="平台开发服务"></el-checkbox>
                    <el-checkbox label="智能硬件产品及服务"></el-checkbox>
                    <el-checkbox label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.digitalCultivationProduct
                            .serviceCategoryOther
                        "
                        :disabled="isDetail"
                      />
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  label="上云用云计划（可多选）"
                  prop="digitalCultivationProduct.cloudPlan"
                >
                  <el-checkbox-group
                    v-model="ruleForm.digitalCultivationProduct.cloudPlan"
                    :disabled="isDetail"
                  >
                    <el-checkbox label="设备上云"></el-checkbox>
                    <el-checkbox label="业务系统上云"></el-checkbox>
                    <el-checkbox label="资源上云（数据、视频等）"></el-checkbox>
                    <el-checkbox
                      label="工具软件上云（数据库、操作系统等）"
                    ></el-checkbox>
                    <el-checkbox label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.digitalCultivationProduct.cloudPlanOther
                        "
                        :disabled="isDetail"
                      />
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  label="项目建设内容"
                  prop="digitalCultivationProduct.projectDetail"
                >
                  <el-input
                    v-model="ruleForm.digitalCultivationProduct.projectDetail"
                    :disabled="isDetail"
                    placeholder="请输入（阐述试点项目建设方案，主要解决的问题，预计达到的效果等，字数500以内）"
                    type="textarea"
                    :autosize="{ minRows: 6 }"
                    maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="合同文件" class="is-required">
                  <div class="flex">
                    <el-button
                      @click="downloadTemplate(1)"
                      type="primary"
                      size="small"
                      class="download-btn"
                      style="margin-right: 10px;margin-top: 5px"
                      >下载模版</el-button
                    >
                    <el-button
                      @click="downloadTemplate(2)"
                      type="primary"
                      size="small"
                      class="download-btn"
                      style="margin-right: 10px;margin-top: 5px;margin-left: 0;"
                      v-if="ruleForm.templateUrl"
                      >下载已上传文件</el-button
                    >
                    <el-upload
                      :action="uploadUrl"
                      :limit="1"
                      :on-exceed="handleFileExceed"
                      :on-success="handleSuccess"
                      :file-list="templateUrlList"
                      :show-file-list="true"
                      :disabled="isDetail"
                    >
                      <el-button
                        size="small"
                        type="success"
                        :disabled="isDetail"
                        >上传</el-button
                      >
                    </el-upload>
                  </div>
                </el-form-item>
                <!-- <el-form-item label="项目进度" prop="progress">
                  <el-input-number
                    v-model="ruleForm.progress"
                    :min="0"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                </el-form-item> -->

                <h5 class="form-subtitle2">审核</h5>
                <div class="auditStatus-container">
                  <div class="auditStatus-title">审核状态</div>
                  <div class="auditStatus-desc">
                    <el-radio-group v-model="ruleForm.auditStatus" disabled>
                      <el-radio :label="1">待审核</el-radio>
                      <el-radio :label="2">审核通过</el-radio>
                      <el-radio :label="3">审核不通过</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="auditStatus-title">原因</div>
                  <div class="audit_reason">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 8 }"
                      placeholder="请输入原因"
                      v-model="ruleForm.auditReason"
                      disabled
                    />
                  </div>
                </div>
              </el-form>
              <div class="flex bewteen vertical">
                <div>
                  <el-button type="info" @click="handleClose">关闭</el-button>
                </div>
                <div>
                  <el-button type="warning" @click="saveDraft" v-if="!isDetail"
                    >保存草稿</el-button
                  >
                  <el-button type="warning" @click="getDraft" v-if="!isDetail"
                    >获取草稿</el-button
                  >
                  <el-button
                    type="primary"
                    @click="beforeSubmit"
                    v-if="!isDetail"
                    >提交</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configs from "@/config/index";
import {
  saveDraft,
  getDraft,
  getCompanyInfo,
  getCompanyInfoEdit,
  getCompanyStuList,
  getProductDeclarationList,
  addOrUpdateDigital,
  getDigitalDetail,
} from "@/api/qy";
import { getTractionUnit } from "@/api/pts";
import { getArea } from "@/api/login";
export default {
  data() {
    return {
      pageType: 1, // 1:新增 2:查看 3:编辑

      ruleForm: {
        baseInfo: {
          frdb: "",
          sscs: "",
          ssq: "",
          zcdz: "",
          tyshxydm: "",
          lxr: "",
          lxfs: "",
        },
        companyName: "",
        companyInfo: "",
        companySize: "",
        companySize2: "",
        digitalLevel: "",
        specialization: "",
        detailTrade: "",
        lastRevenue: "",
        lastProfit: "",
        lastCapitaRevenue: "",
        digitalInputMoney: "",
        companyPerson: "",
        digitalPerson: "",
        digitalCultivationProduct: {
          projectName: "",
          startTime: "",
          endTime: "",
          implementUnit: [],
          serviceCategory: [],
          serviceCategoryOther: "",
          cloudPlan: [],
        },
        digitalCultivationServiceList: [
          {
            companyName: "",
            companyId: "",
            productName: "",
            productId: "",
            code: "",
            supplyUnit: "",
            cultivationProductId: "",
            masterFunction: "",
            money: "",
            way: "",
          },
        ],
        templateUrl: "",
        // progress: 0,
      },
      // 企业列表
      companyList: [],
      // 产品列表
      productList: [],
      // 区域列表
      areaList: [],
      // 图片上传路径
      uploadUrl: "",
      // 上传合同文件列表
      templateUrlList: [],
      isLoadingCompanyList: false,
      isLoadingProductList: false,

      id: "",

      rules: {
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        "baseInfo.frdb": [
          { required: true, message: "请输入法人代表", trigger: "blur" },
        ],
        "baseInfo.sscs": [
          { required: true, message: "请输入所属城市", trigger: "blur" },
        ],
        "baseInfo.ssq": [
          { required: true, message: "请输入所属区", trigger: "blur" },
        ],
        "baseInfo.zcdz": [
          { required: true, message: "请输入注册地址", trigger: "blur" },
        ],
        "baseInfo.tyshxydm": [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        "baseInfo.lxr": [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        "baseInfo.lxfs": [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        companyInfo: [
          { required: true, message: "请输入企业基本情况", trigger: "blur" },
        ],
        companyQuality: [
          { required: true, message: "请选择企业性质", trigger: "change" },
        ],
        companySize: [
          { required: true, message: "请选择企业规模一", trigger: "change" },
        ],
        companySize2: [
          { required: true, message: "请选择企业规模二", trigger: "change" },
        ],
        digitalLevel: [
          {
            required: true,
            message: "请选择中小企业数字化水平测评等级",
            trigger: "change",
          },
        ],
        specialization: [
          { required: true, message: "请选择专精特新情况", trigger: "change" },
        ],
        detailTrade: [
          { required: true, message: "请选择所属细分行业", trigger: "change" },
        ],
        lastRevenue: [
          {
            required: true,
            message: "请输入上年营业收入（万元）",
            trigger: "blur",
          },
        ],
        lastProfit: [
          {
            required: true,
            message: "请输入上年利润（万元）",
            trigger: "blur",
          },
        ],
        lastCapitaRevenue: [
          {
            required: true,
            message: "请输入上年人均营业收入（万元）",
            trigger: "blur",
          },
        ],
        digitalInputMoney: [
          {
            required: true,
            message: "请输入已有数字化转型投入（万元）",
            trigger: "blur",
          },
        ],
        companyPerson: [
          {
            required: true,
            message: "请输入企业总人数（人）",
            trigger: "blur",
          },
        ],
        digitalPerson: [
          {
            required: true,
            message: "请输入企业数字化人员数量（人）",
            trigger: "blur",
          },
        ],
        "digitalCultivationProduct.projectName": [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        "digitalCultivationProduct.startTime": [
          {
            required: true,
            message: "请选择项目实施期限",
            trigger: "change",
          },
        ],
        "digitalCultivationProduct.endTime": [
          {
            required: true,
            message: "请选择项目实施期限",
            trigger: "change",
          },
        ],
        "digitalCultivationProduct.implementUnit": [
          {
            required: true,
            message: "请选择项目实施单位",
            trigger: "change",
          },
        ],
        "digitalCultivationProduct.serviceCategory": [
          {
            required: true,
            message: "请选择产品和服务类别",
            trigger: "change",
          },
        ],
        "digitalCultivationProduct.cloudPlan": [
          {
            required: true,
            message: "请选择上云用云计划",
            trigger: "change",
          },
        ],
        "digitalCultivationProduct.preInput": [
          {
            required: true,
            message: "请输入项目合同总金额",
            trigger: "blur",
          },
        ],
        "digitalCultivationProduct.projectDetail": [
          {
            required: true,
            message: "请输入项目建设内容",
            trigger: "blur",
          },
        ],
        // progress: [
        //   {
        //     required: true,
        //     message: "请输入项目进度",
        //     trigger: "blur",
        //   },
        // ],
      },

      isDetail: false,
      loadingStatus: null,
      tractionUnitList: [],
    };
  },
  created() {
    this.uploadUrl = `${configs.baseUrl}/api/common/upload`;
    // this.getGUANGZHOUAreaList();
  },
  mounted() {
    // 1是填报，2是查看, 3是编辑
    this.pageType = this.$route.query.type;
    if (this.$route.query.type == 1) {
      this.isDetail = false;
      this.getCompanyInfo();
    } else if (this.$route.query.type == 2) {
      this.isDetail = true;
      this.id = this.$route.query.id;
      this.getDigitalDetail();
    } else if (this.$route.query.type == 3) {
      this.isDetail = false;
      this.id = this.$route.query.id;
      this.getDigitalDetail();
    }
    this.getTractionUnit();
  },
  methods: {
    getTractionUnit() {
      getTractionUnit(1).then((res) => {
        console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.tractionUnitList = res.data.selectRespList;
        }
      });
    },
    handleImplementUnitChange(val) {
      if (val.indexOf("无") != -1) {
        this.ruleForm.digitalCultivationProduct.implementUnit = ["无"];
      }
    },
    handleProductChange(value, item) {
      this.productList.forEach((e) => {
        if (e.id == value) {
          this.$set(item, "code", e.code);
        }
      });
    },
    getDigitalDetail() {
      let pdata = {
        id: this.id,
      };
      getDigitalDetail(pdata).then((res) => {
        this.ruleForm = res.data;
        this.ruleForm.baseInfo = JSON.parse(this.ruleForm.baseInfo);
        this.ruleForm.digitalCultivationProduct.cloudPlan = this.ruleForm.digitalCultivationProduct.cloudPlan.split(
          ","
        );
        this.ruleForm.digitalCultivationProduct.serviceCategory = this.ruleForm.digitalCultivationProduct.serviceCategory.split(
          ","
        );
        this.ruleForm.digitalCultivationProduct.implementUnit = this.ruleForm.digitalCultivationProduct.implementUnit.split(
          ","
        );
        let list = this.ruleForm.templateUrl.split("/");
        this.templateUrlList = [
          { name: list[2], fullurl: this.ruleForm.templateUrl },
        ];
        this.getCompanyStuList();
        let ids = [];
        this.ruleForm.digitalCultivationServiceList.forEach((item) => {
          if (ids.indexOf(Number(item.companyId)) == -1) {
            ids.push(item.companyId);
            this.handleCompanyChange(item.companyId, 2);
          }
        });
      });
    },
    downloadTemplate(type) {
      let url = "";
      if (type == 1) {
        url = `https://suizz.ceprei.com:9204/txt/附件2 中小企业数字化改造服务合同（模板）.docx`;
      } else {
        url = `${configs.baseUrl}/${this.ruleForm.templateUrl}`;
      }
      console.log("url", url);
      var a = document.createElement("a");
      a.href = url;
      a.download = `附件2 广州市中小企业数字化改造服务合同（模板）.docx`; // 设置下载文件名
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // 清理 Blob 对象
      URL.revokeObjectURL(url);
    },
    handleFileExceed() {
      this.$message.error("只能上传一个文件");
    },
    handleSuccess(response, file) {
      if (response.code == 1) {
        let obj = {
          name: response.data.name,
          fullurl: response.data.fullurl,
        };
        this.templateUrlList[0] = obj;
      }
      console.log("this.telist", this.templateUrlList);
    },
    delService(index) {
      this.ruleForm.digitalCultivationServiceList.splice(index, 1);
    },
    addService() {
      if (this.isDetail) return;
      this.ruleForm.digitalCultivationServiceList.push({
        companyName: "",
        companyId: "",
        productName: "",
        productId: "",
        code: "",
        supplyUnit: "",
        cultivationProductId: "",
        masterFunction: "",
        money: "",
        way: "",
      });
    },
    getCompanyInfo() {
      let pdata = {
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      getCompanyInfoEdit(pdata).then((res) => {
        this.ruleForm.digitalLevel = res.data.digitalLevel;
        this.ruleForm.companyName = res.data.name;
        this.ruleForm.companyName = res.data.name;
        this.ruleForm.baseInfo.tyshxydm = res.data.jgm;
      });
    },
    handleClose() {
      this.$router.push({ name: "enterpriseSbzj" });
    },
    beforeSubmit() {
      let isValidate = true;
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          isValidate = false;
          console.log("1");
        }
      });
      this.$refs.ruleForm1.validate((valid) => {
        if (!valid) {
          isValidate = false;
          console.log("2");
        }
      });
      this.ruleForm.digitalCultivationServiceList.forEach((item) => {
        for (let key in item) {
          if (item.companyId == "" || item.companyId == null) {
            isValidate = false;
          }
          if (item.productId == "" || item.productId == null) {
            isValidate = false;
          }
          if (item.code == "" || item.code == null) {
            isValidate = false;
          }
          // if (item.supplyUnit == "" || item.supplyUnit == null) {
          //   isValidate = false;
          // }
          if (item.masterFunction == "" || item.masterFunction == null) {
            isValidate = false;
          }
          if (item.money == "" || item.money == null) {
            isValidate = false;
          }
          if (item.way == "" || item.way == null) {
            isValidate = false;
          }
        }
      });
      if (this.templateUrlList.length == 0) {
        isValidate = false;
        console.log("4");
      } else {
        this.ruleForm.templateUrl = this.templateUrlList[0].fullurl;
      }
      if (isValidate) {
        let pdata = {
          ...this.ruleForm,
        };
        pdata.baseInfo = JSON.stringify(this.ruleForm.baseInfo);
        pdata.digitalCultivationProduct = JSON.parse(
          JSON.stringify(this.ruleForm.digitalCultivationProduct)
        );
        pdata.digitalCultivationProduct.serviceCategory = pdata.digitalCultivationProduct.serviceCategory.join(
          ","
        );
        pdata.digitalCultivationProduct.cloudPlan = pdata.digitalCultivationProduct.cloudPlan.join(
          ","
        );
        pdata.digitalCultivationProduct.implementUnit = pdata.digitalCultivationProduct.implementUnit.join(
          ","
        );
        pdata.digitalCultivationProduct = JSON.stringify(
          pdata.digitalCultivationProduct
        );
        pdata.digitalCultivationServiceList = JSON.stringify(
          this.ruleForm.digitalCultivationServiceList
        );
        pdata.userId = JSON.parse(sessionStorage.getItem("user")).id;
        console.log(
          "pdata",
          pdata,
          JSON.parse(sessionStorage.getItem("user")).id
        );
        if (this.$route.query.type == 3 && this.$route.query.id) {
          pdata.id = this.$route.query.id;
        }
        // type为1的时候为新增，新增不传ID
        if (this.$route.query.type == 1) {
          delete pdata.id;
        }
        addOrUpdateDigital(pdata).then((res) => {
          console.log("res", res);
          if (res.code == 200) {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.handleClose();
            }, 1800);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.error("请填写完整信息");
      }
      console.log("isValidate", isValidate);
    },
    saveDraft() {
      let content = {
        ...this.ruleForm,
        companyList: this.companyList,
        productList: this.productList,
        templateUrlList: this.templateUrlList,
      };
      console.log("content", content);
      let pdata = {
        type: 6,
        content: JSON.stringify(content),
      };
      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存草稿成功");
        } else {
          this.$message.error("保存草稿失败");
        }
      });
    },
    getDraft() {
      let pdata = {
        type: 6,
      };
      getDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("获取成功");
          let data = JSON.parse(res.data.content);
          console.log("data", data);

          this.ruleForm = JSON.parse(res.data.content);
          delete this.ruleForm.companyList;
          delete this.ruleForm.productList;
          delete this.ruleForm.templateUrlList;
          this.companyList = data.companyList || [];
          this.productList = data.productList || [];
          this.templateUrlList = data.templateUrlList || [];
        } else {
          this.$message.error("获取失败");
        }
      });
    },

    // 区域下拉
    getGUANGZHOUAreaList() {
      let pdata = {
        pid: 236,
      };
      getArea(pdata).then((res) => {
        this.areaList = res.data;
      });
    },

    // 获取企业下拉
    getCompanyStuList(companyName = "") {
      this.isLoadingCompanyList = true;
      let pdata = {
        companyName: companyName,
      };
      getCompanyStuList(pdata)
        .then((res) => {
          console.log("res", res);
          this.companyList = res.data;
        })
        .finally(() => {
          this.isLoadingCompanyList = false;
        });
    },
    handleCompanyChange(userId, type = 1, row = {}) {
      this.isLoadingProductList = true;
      this.$set(row, "code", "");
      this.$set(row, "masterFunction", "");
      this.$set(row, "money", "");
      this.$set(row, "productId", "");
      this.$set(row, "way", "");
      let pdata = {
        userId,
      };
      getProductDeclarationList(pdata)
        .then((res) => {
          if (type == 1) {
            this.productList = res.data;
          } else {
            this.productList = this.productList.concat(res.data);
          }
        })
        .finally(() => {
          this.isLoadingProductList = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.ss-input {
  ::v-deep .el-input__inner {
    height: 18px;
    width: 100px;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #efefef;
  }
}

.sf-input {
  ::v-deep .el-input__inner {
    width: 300px;
  }
}
.main {
  background-color: #f0f5ff;
}
.container {
  .same-container {
    width: 100%;
    height: 100%;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;

        .top-nav {
          margin-bottom: 30px;
        }

        .form-container {
          width: 100%;
          padding: 40px 200px;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          margin-top: 30px;

          .form-title-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .form-title {
              font-family: Microsoft YaHei;
              font-size: 24px;
              font-weight: bold;
              line-height: normal;
              text-align: center;
              color: #333333;

              margin: 0;
              margin-bottom: 10px;
            }
            .line {
              width: 150px;
              height: 4px;
              border-radius: 2px;
              background: #205dc3;
            }
          }

          h5.subheadings {
            font-family: Microsoft YaHei;
            font-size: 22px;
            font-weight: bold;
            font-variation-settings: "opsz" auto;
            color: #333333;

            margin-top: 80px;
            margin-bottom: 40px;
          }
          .ruleForm {
            .sf-content {
              display: flex;
              // align-items: center;
              span {
                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 18px;
                color: #666666;

                margin-left: 50px;
              }
            }

            .zmcl-content {
              .zmcl-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                // height: 100px;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid #dcdfe6;
                margin-top: 20px;

                .zmcl-content-left {
                  width: 70%;

                  font-family: Microsoft YaHei;
                  font-size: 16px;
                  line-height: 26px;
                  color: #2282f9;
                }
              }
            }

            .sppgfj-upload {
              display: flex;
              justify-content: space-between;
              align-items: center;

              width: 100%;
              // height: 100px;
              padding: 15px;
              box-sizing: border-box;
              border-radius: 5px;
              border: 1px solid #dcdfe6;
              margin-top: 20px;

              .sppgfj-content-left {
                width: 70%;

                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 26px;
                color: #2282f9;
              }
            }

            .radio-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qyms-radio-group {
              display: flex;
              flex-direction: column;

              /deep/.qyms-radio-option {
                display: flex;
                // align-items: center;
                margin-bottom: 30px;
                .el-radio__label {
                  display: inline-block;
                  display: flex;
                  flex-direction: column;
                  .qyms-description {
                    // display: inline-block;
                    width: 100%;
                    // height: 100px;
                    margin-top: 10px;

                    font-family: Microsoft YaHei;
                    font-size: 14px;
                    line-height: 26px;
                    color: #666666;
                    text-wrap: wrap;
                  }
                }
              }
            }

            .checkbox-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                // margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qydw-content {
              .qydw-radio {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                margin-left: 30px;

                .qydw-item {
                  width: 350px;
                  display: flex;
                  flex-direction: column;
                  margin-right: 30px;

                  .qydw-item-title {
                    font-family: Microsoft YaHei;
                    font-size: 18px;
                    font-weight: bold;
                    font-variation-settings: "opsz" auto;
                    color: #333333;

                    margin-bottom: 30px;
                  }

                  .qydw-radio-option {
                    margin-bottom: 30px;
                  }
                }
              }
            }
            .form-subtitle2 {
              font-size: 18px;
              margin-top: 50px;
            }
            .auditStatus-container {
              // margin-top: 30px;
              .auditStatus-title {
                margin-top: 30px;
                margin-bottom: 10px;

                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.cancel-btn {
  height: 38px;
  background-color: #e2e2e2;
  border: none;
  color: #333;
  &:hover {
    background-color: #efefef;
    color: #333;
  }
}
.btns {
  margin-top: 38px;
  .draft-btn {
    height: 38px;
    background-color: #ea7100;
    border: none;
    color: #fff;
    &:hover {
      background-color: #ffb774;
      color: #fff;
    }
  }
  .btn {
    margin-right: 10px;
  }
}

.dellink {
  cursor: pointer;
  margin-left: 5px;
  color: #409eff;
  &:hover {
    color: #66b1ff;
  }
}
.service-list {
  .service-item {
    margin-bottom: 22px;
    .service-content {
      display: flex;
      width: 100%;
      margin-top: 22px;
      .content-title {
        width: 128px;
        color: #606266;
      }
      .content-form {
        width: calc(100% - 128px);
      }
    }
    .service-title {
      font-size: 16px;
      font-weight: bold;
      color: #205dc3;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
      .service-del {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}
.service-add {
  width: fit-content;
  color: #2282f9;
  margin-left: 128px;
  div {
    line-height: 1.8;
  }
  img {
    height: 50px;
    width: 50px;
  }
}
.download-btn {
  height: fit-content;
}
.radio-item {
  margin-bottom: 5px;
}
// ::v-deep .el-upload-list {
//   position: relative;
//   left: -88px;
// }
::v-deep .el-input__count {
  // position: relative;
  background-color: transparent !important;
  bottom: -5px !important;
  right: -52px !important;
}
</style>
