<template>
  <div class="list3-main">
    <div class="list3-content">
      <div class="top-nav">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item
            :to="{
              path: '/government/sbzj',
            }"
            >申报征集</el-breadcrumb-item
          >
          <el-breadcrumb-item>试点企业改造意向摸查</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="list3-main-content">
        <div class="flex vertical bewteen">
          <el-tabs
            v-model="activeName"
            @tab-click="handleClickTabs"
            style="flex: 1;"
          >
            <el-tab-pane label="已审批" name="ysp"></el-tab-pane>
            <el-tab-pane label="待审批" name="dsp"></el-tab-pane>
          </el-tabs>
          <el-select v-model="value" placeholder="请选择导出类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            @click="handleExport"
            type="primary"
            style="margin-left: 10px;"
            >导出</el-button
          >
        </div>
        <div>
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="企业名称">
              <el-input
                v-model="searchForm.companyName"
                placeholder="请输入企业名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="统一信用代码">
              <el-input
                v-model="searchForm.creditCode"
                placeholder="统一信用代码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :header-cell-style="getTableRowStyle"
          :cell-style="getTableCellStyle"
        >
          <el-table-column type="index" label="序号" width="58">
          </el-table-column>
          <el-table-column prop="enterpriseName" label="企业名称">
          </el-table-column>
          <el-table-column
            prop="socialCreditCode"
            label="统一信用代码"
            width="128px"
          >
          </el-table-column>
          <el-table-column
            prop="administrativeRegion"
            label="所属行政区"
            width="108px"
          >
          </el-table-column>
          <el-table-column prop="contactPerson" label="联系人">
          </el-table-column>
          <el-table-column prop="contactPhone" label="联系电话">
          </el-table-column>
          <el-table-column
            prop="enterpriseIntroduction"
            label="企业简介"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="subIndustry"
            label="所属细分行业"
            width="128px"
          >
          </el-table-column>
          <el-table-column
            prop="smallerMediumEnterprise"
            label="是否为中小企业"
            width="148px"
          >
          </el-table-column>
          <el-table-column
            prop="manufacturingEnterprise"
            label="是否为制造业企业"
            width="168px"
          >
          </el-table-column>
          <el-table-column
            prop="largeScaleEnterprise"
            label="是否为规上企业"
            width="148px"
          >
          </el-table-column>
          <el-table-column
            prop="specializedAndNewEnterprise"
            label="是否为专精特新企业"
            width="188px"
          >
          </el-table-column>
          <el-table-column
            prop="digitalTransformationIntent"
            label="企业数字化改造意愿强烈程度"
            width="238px"
          >
          </el-table-column>
          <el-table-column
            prop="plannedInvestmentForDigitalTransformation"
            label="企业通过数字化转型拟投资额度"
            width="258px"
          >
          </el-table-column>
          <el-table-column
            prop="transformationNeeds"
            label="企业当前急需进行数字化转型的业务场景或业务环节"
            show-overflow-tooltip
            width="348px"
          >
          </el-table-column>
          <el-table-column
            prop="pullService"
            label="是否有意向数字化转型服务商并建立联系或合作"
            show-overflow-tooltip
            width="328px"
          >
          </el-table-column>
          <el-table-column prop="auditStatus" label="审核状态" fixed="right">
            <template slot-scope="scope">
              <span style="color: #2282F9;" v-if="scope.row.auditStatus == 1"
                >待审核</span
              >
              <span style="color: #349827;" v-if="scope.row.auditStatus == 2"
                >审核通过</span
              >
              <span style="color: #FF0000;" v-if="scope.row.auditStatus == 3"
                >审核拒绝</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            width="108"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="beforeVerify(scope.row.id)"
                >审核</el-button
              >
              <el-button type="text" @click="handleDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <el-pagination
        class="pagination"
        :total="total"
        :current-page="page"
        :layout="'prev, pager, next'"
        @current-change="handlePageChange"
      >
      </el-pagination>

      <el-dialog
        title="审批"
        :visible.sync="verifyDialog"
        width="30%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="verifyForm" label-width="80px">
          <el-form-item label="审核状态">
            <el-radio-group v-model="verifyForm.auditStatus">
              <el-radio :label="2">审核通过</el-radio>
              <el-radio :label="3">审核拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核原因">
            <el-input
              type="textarea"
              v-model="verifyForm.auditReason"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleVerify">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getDigitalReady,
  verifyDigitalReady,
  exportDigitalReadyDetail,
} from "@/api/zf";
import { formatDateTime } from "@/utils/common";
export default {
  data() {
    return {
      options: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "审核不通过",
        },
      ],
      value: "",
      tableData: [],

      // 分页
      total: 0,
      page: 1,

      activeName: "ysp",

      searchForm: {
        companyName: "",
        creditCode: "",
      },

      verifyDialog: false,
      verifyId: null,
      verifyForm: {
        auditReason: "",
        auditStatus: 2,
      },
    };
  },
  created() {
    this.getList();
  },
  computed: {},
  methods: {
    handleClose() {
      this.verifyDialog = false;
      this.verifyForm = {
        auditReason: "",
        auditStatus: 2,
      };
    },
    handleVerify() {
      let pdata = {
        id: this.verifyId,
        ...this.verifyForm,
      };
      verifyDigitalReady(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.handleClose();
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch((err) => {
          console.log("error", err);
          this.$message.error("操作失败");
        })
        .finally(() => {
          this.getList();
        });
    },
    beforeVerify(id) {
      this.verifyId = id;
      this.verifyDialog = true;
    },
    handleExport() {
      // let pdata = {
      //   auditStatus: this.activeName == "ysp" ? 2 : 1,
      // };
      let pdata = {
        auditStatus: this.value,
      };
      exportDigitalReadyDetail(pdata).then((res) => {
        console.log("res", res);

        var blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        var url = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `试点企业改造意向摸查${new Date().toLocaleString()}.xls`; // 设置下载文件名
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // 清理 Blob 对象
        URL.revokeObjectURL(url);
      });
    },
    handleDetail(row) {
      this.$router.push({
        name: "ApplicationFormFifth",
        // type = 1为申报
        // type = 2为查看
        query: {
          type: 2,
          id: row.id,
        },
      });
    },
    // handleDelete(row) {
    //   let pdata = {
    //     id: row.id,
    //   };
    //   deleteEcological(pdata)
    //     .then((res) => {
    //       console.log("res", res);
    //       if (res.code == 200) {
    //         this.$message.success("删除成功");
    //       }
    //     })
    //     .finally(() => {
    //       this.getList();
    //       this.$refs[`${row.id}popover`].doClose();
    //     });
    // },
    handleClickTabs(val) {
      this.getList();
    },
    formatDateTime(time) {
      return formatDateTime(time);
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      getDigitalReady({
        pageNum: this.page,
        pageSize: 10,
        auditStatus: this.activeName == "dsp" ? 1 : 4,
        ...this.searchForm,
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getTableRowStyle() {
      return "background-color: #eef2ff;text-align: center;";
    },
    getTableCellStyle() {
      return "text-align: center;";
    },
  },
};
</script>

<style lang="less" scoped>
.list3-main {
  background-color: #eff4fe;
  width: 100%;
  min-height: calc(100vh -408px);
  height: fit-content;

  .list3-content {
    width: 82%;
    height: 100%;
    margin: 0 auto;
    min-height: 700px;

    .top-nav {
      margin-bottom: 30px;
    }
    .list3-main-content {
      background-color: #fff;
      height: fit-content;
      border-radius: 10px;
      padding: 10px 18px;
    }
    .el-pagination {
      width: 100%;
      margin: 10px 0 0;
      ::v-deep .el-pager li,
      ::v-deep .btn-prev,
      ::v-deep .btn-next {
        background-color: transparent;
        padding: 0;
        margin: 0 4px;
        min-width: 32px;
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
      ::v-deep .el-pager li:hover {
        background: #e3ecfc;
        color: #205dc3;
        border-radius: 4px;
      }
      ::v-deep .el-pager li.active {
        background: linear-gradient(to bottom, #145dff, #0699fd);
        // padding: 0;
        // margin: 0 4px;
        // min-width: 32px;
        // width: 32px;
        // height: 32px;
        // line-height: 32px;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}
::v-deep .el-tabs__item.is-active {
  color: #205dc3;
  font-size: 18px;
}
::v-deep .el-tabs__item {
  font-size: 18px;
  color: #666666;
  margin-bottom: 10px;
}
::v-deep .el-tabs__item:hover {
  color: #205dc3;
}
::v-deep .el-tabs .el-tabs__header .el-tabs__active-bar {
  width: 78px !important;
  height: 5px !important;
  border-radius: 5px !important;
}
</style>
