<template>
  <el-dialog :visible.sync="interpretationDialog" width="788px" @close="cancel">
    <div class="interpretation-container">
      <h3 class="title">相关名词解释</h3>
      <p class="content">
        <span class="little-title">设备数字化率：</span>
        是指企业现有生产设备的数字化程度，即数字化生产设备占总生产设备数量的比例，其数值=数字化生产设备数量/总生产设备数量×100%。
      </p>
      <p class="content">
        <span class="little-title">设备联网率：</span>
        指联网设备占设备总数的比重，其数值=实现联网的生产设备数量/总生产设备数量×100%。
      </p>
      <p class="content">
        <span class="little-title">数字化人才：</span>
        是指具备ICT(信息通信技术)专业技能和补充技能的人才，他们在企业内部的各个岗位上发挥作用，包括传统信息技术部门的技术人员、业务部门中精通信息系统并熟练操作的专业人员，以及在数字化转型中新兴的横跨各种组织职能的角色。数字化人才通常划分三个层级：其一是数字化技术人才，掌握计算机、大数据、人工智能、通信等相关的数字化技术；其二是数字化管理人才，从战略上落地实施数字化战术，深谙商业价值、经营理念；其三是数字化应用人才，以企业核心资产的价值推动业务数字化应用能力增长，具有优化重构业务增长的分析能力。
      </p>
      <p class="content">
        <span class="little-title">综合能源消费量：</span>
        指企业（单位）在报告期内工业生产实际消费的各种能源(扣除能源加工转换产出和能源回收利用等重复因素)的总和。计算方法参考国家统计局制定的《能源报表统计制度》中的《能源购进、消费与库存》(205-1表)和《能源加工转换与回收利用》(205-2表)。
      </p>
      <p class="content">
        （一）没有能源加工转换和回收利用活动的调查单位：
      </p>
      <p class="content">
        综合能源消费量=工业生产消费(205-1表第5列能源合计)
      </p>
      <p class="content">
        （二）有能源加工转换或回收利用活动的调查单位：
      </p>
      <p class="content">
        综合能源消费量=工业生产消费(205-1表第5列能源合计)-能源加工转换产出(205-2表第11列能源合计)-回收利用(205-2表第12列能源合计)
      </p>
      <p class="content">
        <span class="little-title">注：</span>
        《能源购进、消费与库存》(205-1表)和《能源加工转换与回收利用》(205-2表)填报目录包含各种能源（如原煤、焦炭、天然气等）、电力和热力、用于燃料的生活垃圾和生物质能等。计算综合能源消费量时，各类能源消费量需以标准煤为单位计量。下表为部分能源折标准煤系数示意，详见《能源统计报表制度》填报目录。
      </p>
      <div class="table-container">
        <h4 class="table-title">表1能源折标准煤系数表（部分）</h4>
        <el-table
          :data="tableData1"
          :header-cell-style="{
            'text-align': 'center',
            'text-weight': 600,
            color: '#000',
          }"
          :cell-style="{ 'text-align': 'center' }"
          border
          style="width: 100%"
        >
          <el-table-column prop="name" label="能源名称"></el-table-column>
          <el-table-column
            prop="unit"
            label="计量单位"
            width="135"
          ></el-table-column>
          <el-table-column
            prop="value"
            label="参考折标准煤系数"
          ></el-table-column>
        </el-table>
        <div class="table-tip">
          能源消费量(吨标准煤)=能源消费量(原单位)×折标准煤系数
        </div>
      </div>
      <p class="content">
        示例：某企业本年度耗电 310 万千瓦时，消耗高炉煤气
        1.5万立方米。根据上述附表和计算公式，将电力折算以标准煤计量
        的消费量为310×1.229=380.99(吨标准煤)；将高炉煤气折算以标
        准煤计量的消费量为1.5×1.286=1.929(吨标准煤)。该企业本年度
        能源消费总量为380.99+1.929=382.919(吨标准煤)。
      </p>
      <p class="content">
        <span class="little-title">关键工序：</span>
        指对成品的质量、性能、功能、寿命、可靠性及成本等有直接影响的工序；产品重要质量特性形成的工序；工艺复杂，质量容易波动，对工人技艺要求高或总是发生问题较多的
        工序。
      </p>
      <p class="content">
        <span class="little-title">业务环节：</span>
        指产品设计、工艺设计、营销管理、售后服务、计划排程、生产管控、质量管理、设备管理、安全生产、能耗管理、采购管理、仓储物流、财务管理、人力资源等环节。
      </p>
      <p class="content">
        <span class="little-title">规范化管理：</span>
        企业对线下的流程化业务在线上实现清晰的标 准化管理。
      </p>
      <p class="content">
        <span class="little-title">智能仓储：</span>
        指应用数字化技术，依据实际生产作业计划，实 现物料自动入库、盘库或出库。
      </p>
      <p class="content">
        <span class="little-title">供应链可视化：</span>
        指搭建供应链管理系统（SCM），融合数字 化技术，实现供应链可视化监控。
      </p>
      <p class="content">
        <span class="little-title">数据驱动平台：</span>
        指以数据为核心，通过数据采集、分析和应用，实现对企业运营、管理和决策的支持和优化的平台。
      </p>
      <p class="content">
        注：以上所给出的名词解释仅适用于《中小企业数字化水平评测指标（2024年版）》。
      </p>
    </div>
  </el-dialog>
</template>

<script>
import { color } from "echarts";

export default {
  name: "interpretation",
  data() {
    return {
      interpretationDialog: false,

      tableData1: [
        {
          id: 1,
          name: "原煤（无烟煤）",
          unit: "吨",
          value: "0.9428 吨标准煤/吨",
        },
        {
          id: 2,
          name: "原煤（炼焦烟煤）",
          unit: "吨",
          value: "0.9 吨标准煤/吨",
        },
        {
          id: 3,
          name: "原煤（一般烟煤）",
          unit: "吨",
          value: "0.7143 吨标准煤/吨",
        },
        {
          id: 4,
          name: "原煤（褐煤）",
          unit: "吨",
          value: "0.4286 吨标准煤/吨",
        },
        {
          id: 5,
          name: "洗精煤",
          unit: "吨",
          value: "0.9 吨标准煤/吨",
        },
        {
          id: 6,
          name: "高炉煤气",
          unit: "万立方米",
          value: "1.286 吨标准煤/万立方米",
        },
        {
          id: 7,
          name: "转炉煤气",
          unit: "万立方米",
          value: "2.714 吨标准煤/万立方米",
        },
        {
          id: 8,
          name: "其他煤气",
          unit: "万立方米",
          value: "1.786 吨标准煤/万立方米",
        },
        {
          id: 9,
          name: "热力",
          unit: "百万千焦",
          value: "0.0341 吨标准煤/百万千焦",
        },
        {
          id: 10,
          name: "电力",
          unit: "万千瓦时",
          value: "1.229 吨标准煤/万千瓦时",
        },
        {
          id: 11,
          name: "城市生活垃圾（用于燃料）",
          unit: "吨",
          value: "0.2714 吨标准煤/吨",
        },
        {
          id: 12,
          name: "生物质能（用于燃料）",
          unit: "吨标准煤",
          value: "1",
        },
        {
          id: 13,
          name: "余热余压",
          unit: "百万千焦",
          value: "0.0341 吨标准煤/百万千焦",
        },
        {
          id: 14,
          name: "工业废料（用于燃料）",
          unit: "吨",
          value: "0.4285 吨标准煤/吨",
        },
        {
          id: 15,
          name: "其他燃料",
          unit: "吨标准煤",
          value: "1",
        },
      ],
    };
  },
  methods: {
    onShow() {
      this.interpretationDialog = true;
    },
    onCancel() {
      this.interpretationDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.interpretation-container {
  padding: 0 30px;
  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;

    margin-bottom: 20px;
  }
  .content {
    font-size: 16px;
    line-height: 2;
    text-indent: 2em;
    .little-title {
      font-weight: 600;
    }
  }
  .table-container {
    .table-title {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .table-tip {
      font-size: 14px;
      line-height: 2;
      text-align: center;
    }
  }
}
</style>
