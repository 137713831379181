<template>
  <div class="declare_main">
    <div class="declare_body">
      <!-- 面包屑导航 -->
      <div class="crumbs-content">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/enterprise/sbzj' }"
            >申报征集</el-breadcrumb-item
          >
          <el-breadcrumb-item>试点企业改造意向摸查</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="form-container">
        <div class="form-title-content">
          <h1 class="form-title">试点企业改造意向摸查</h1>
          <div class="line"></div>
        </div>
        <el-form
          label-position="left"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="250px"
          class="ruleForm"
        >
          <h5 class="subheadings">一、企业基本信息</h5>
          <el-form-item label="企业名称" prop="enterpriseName">
            <el-input
              v-model="ruleForm.enterpriseName"
              placeholder="请输入企业名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="socialCreditCode">
            <el-input
              v-model="ruleForm.socialCreditCode"
              placeholder="请输入统一社会信用代码"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="所属行政区" prop="administrativeRegion">
            <el-select
              v-model="ruleForm.administrativeRegion"
              placeholder="请选择所属行政区"
              clearable
              style="width: 120px"
              :disabled="formType == 2"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name_cn"
                :value="item.name_cn"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="注册地址"
            class="is-required"
            prop="registeredAddress"
          >
            <AreaSelect
              :isDisabled="formType == 2"
              @submitArea="handleRegisteredAddress"
              :defVal="registeredAddress"
            ></AreaSelect>
          </el-form-item>
          <el-form-item label="联系人" prop="contactPerson">
            <el-input
              v-model="ruleForm.contactPerson"
              placeholder="请输入联系人"
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPhone">
            <el-input
              v-model="ruleForm.contactPhone"
              placeholder="请输入联系电话"
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业简介" prop="enterpriseIntroduction">
            <el-input
              type="textarea"
              class="limit_area"
              v-model="ruleForm.enterpriseIntroduction"
              :autosize="{ minRows: 6 }"
              placeholder="请输入企业简介"
              maxlength="500"
              show-word-limit
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属细分行业" prop="subIndustry">
            <el-radio-group
              v-model="ruleForm.subIndustry"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="智能网联和新能源汽车（零部件）"></el-radio>
              <el-radio label="工业母机和机器人"></el-radio>
              <el-radio label="时尚美妆"></el-radio>
              <el-radio label="定制家居"></el-radio>
              <el-radio label="服装"></el-radio>
              <el-radio label="箱包"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="smallerMediumEnterprise">
            <span slot="label">
              是否为中小企业
              <el-tooltip
                effect="dark"
                content="企业划型标准依据《工业和信息化部 统计局 发展改革委 财政部关于印发中小企业划型标准规定的通知》（工信部联企业〔2011〕300号）"
                placement="top-start"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
            <div class="radio-content">
              <el-radio-group
                v-model="ruleForm.smallerMediumEnterprise"
                size="medium"
                :disabled="formType == 2"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item prop="manufacturingEnterprise">
            <span slot="label">
              是否为制造业企业
              <el-tooltip
                effect="dark"
                content="行业划分标准依据《国民经济行业分类（GB/T 4754-2017）》"
                placement="top-start"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
            <div class="radio-content">
              <el-radio-group
                v-model="ruleForm.manufacturingEnterprise"
                size="medium"
                :disabled="formType == 2"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item prop="largeScaleEnterprise">
            <span slot="label">
              是否为规上企业
              <el-tooltip
                effect="dark"
                content="规上工业企业指年主营业务收入达到2000万元及以上的工业企业‌。"
                placement="top-start"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </span>
            <el-radio-group
              v-model="ruleForm.largeScaleEnterprise"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="是否为专精特新企业"
            prop="specializedAndNewEnterprise"
          >
            <el-radio-group
              v-model="ruleForm.specializedAndNewEnterprise"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="专精特新“小巨人”企业"></el-radio>
              <el-radio label="专精特新中小企业"></el-radio>
              <el-radio label="创新型中小企业"></el-radio>
              <el-radio label="其他中小企业"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="rulesPlace">
            <span slot="label">
              拟改造工厂是否在广州
            </span>
            <el-radio-group
              v-model="ruleForm.rulesPlace"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="是"></el-radio>
              <el-radio label="否">
                <span>否：</span>
                <el-input
                  class="other-input"
                  v-model="ruleForm.otherRulesPlace"
                  :disabled="formType == 2"
                />
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="2024年营业收入（万元）" prop="lastYearMoney">
            <el-input
              v-model="ruleForm.lastYearMoney"
              placeholder="请输入2024年营业收入（万元）"
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>
          <el-form-item label="员工数量（人）" prop="employeeCount">
            <el-input
              v-model="ruleForm.employeeCount"
              placeholder="请输入员工数量（人）"
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>

          <el-form-item label="主营业务或主营产品" prop="mainBusinessOrProduct">
            <el-input
              v-model="ruleForm.mainBusinessOrProduct"
              placeholder="请输入主营业务或主营产品"
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="所属细分行业开展业务证明材料"
            prop="subIndustryProof"
          >
            <div class="zmcl-content">
              <el-input
                type="textarea"
                placeholder="若营业执照中经营范围可明显体现与试点细分行业关系的直接上传营业执照；
若营业执照不能直接体现与试点行业关系的，请提供具备申报细分行业客户服务案例合同、相关产品手册或其他证明属于所申报行业关联配套产业的证明材料，（本项关系到申报企业资质审核，请按以上要求提供）"
                :autosize="{ minRows: 6 }"
                v-model="ruleForm.subIndustryProof"
                maxlength="500"
                show-word-limit
                :disabled="formType == 2"
              ></el-input>
              <div class="zmcl-upload">
                <div class="zmcl-content-left">
                  <div
                    v-for="(item, index) in subIndustryFileList"
                    :key="index"
                    class="flex vertical"
                    style="margin-bottom: 8px"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url"
                      :download="item.name"
                      :underline="false"
                      @click="getFile(item)"
                    >
                      {{ item.name }}
                    </el-link>
                    <i
                      v-if="formType != 2"
                      class="el-icon-error dellink"
                      @click="handleDelFile(index)"
                    ></i>
                  </div>
                </div>
                <div class="zmcl-content-right">
                  <el-upload
                    ref="subIndustryFileList"
                    :action="uploadUrl"
                    :limit="5"
                    :on-exceed="handleFileExceed"
                    :on-remove="
                      (file) => handleRemove(file, 'subIndustryFileList')
                    "
                    :on-success="
                      (response, file) =>
                        handleFileSuccess(response, file, 'subIndustryFileList')
                    "
                    :on-change="
                      (file, fileList) =>
                        handleFileChange(file, fileList, 'subIndustryFileList')
                    "
                    :file-list="subIndustryFileList"
                    :show-file-list="false"
                    :disabled="formType == 2"
                  >
                    <el-button
                      :disabled="formType == 2"
                      size="small"
                      type="primary"
                      >选择文件</el-button
                    >
                    <div
                      slot="tip"
                      class="el-upload__tip"
                      style="text-wrap: nowrap"
                    >
                      注:最多可上传5个文件, 文件上传大小不能超过20M
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="数字化水平测评结果及文件上传"
            prop="questionResultFile.result"
          >
            <div class="zmcl-content">
              <el-radio-group
                v-model="ruleForm.questionResultFile.result"
                size="medium"
                :disabled="formType == 2"
              >
                <el-radio label="无等级"></el-radio>
                <el-radio label="一级"></el-radio>
                <el-radio label="二级"></el-radio>
                <el-radio label="三级"></el-radio>
                <el-radio label="四级"></el-radio>
              </el-radio-group>
              <div class="zmcl-upload">
                <div class="zmcl-content-left">
                  <div
                    v-for="(item, index) in fileListAF"
                    :key="index"
                    class="flex vertical"
                    style="margin-bottom: 8px"
                  >
                    <el-link
                      type="primary"
                      target="_blank"
                      :href="item.url"
                      :download="item.name"
                      :underline="false"
                      @click="getFile(item)"
                    >
                      {{ item.name }}
                    </el-link>
                    <i
                      v-if="formType != 2"
                      class="el-icon-error dellink"
                      @click="handleDelFileAF(index)"
                    ></i>
                  </div>
                </div>
                <div class="zmcl-content-right">
                  <el-upload
                    ref="questionResultFile"
                    :action="uploadUrl"
                    :limit="5"
                    :on-exceed="handleFileExceed"
                    :on-success="
                      (response, file) =>
                        handleFileSuccess(response, file, 'questionResultFile')
                    "
                    :on-change="
                      (file, fileList) =>
                        handleFileChange(file, fileList, 'questionResultFile')
                    "
                    :on-remove="handleDelFileAF"
                    :file-list="fileListAF"
                    :show-file-list="false"
                    :disabled="formType == 2"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      :disabled="formType == 2"
                      >选择文件</el-button
                    >
                    <el-button
                      size="small"
                      @click.stop="handleGoPC"
                      type="primary"
                      :disabled="formType == 2"
                      >去评测</el-button
                    >
                    <div
                      slot="tip"
                      class="el-upload__tip"
                      style="text-wrap: nowrap"
                    >
                      注:最多可上传5个文件, 文件上传大小不能超过20M
                    </div>
                  </el-upload>
                </div>
              </div>
              <div class="text-tip">
                <span>数字化水平自测评参考链接</span>
                <!-- <a
                  style="color: #000"
                  href="http://localhost:8081//#/selfAssessmentQuestion"
                  >http://localhost:8081//#/selfAssessmentQuestion</a
                > -->
                <el-link
                  type="primary"
                  target="_self"
                  href="https://suizz.ceprei.com:888/#/selfAssessmentQuestion"
                  >https://suizz.ceprei.com:888/#/selfAssessmentQuestion</el-link
                >
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-container">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm1"
          label-width="250px"
          class="ruleForm"
          label-position="left"
        >
          <h5 class="subheadings">二、企业数字化转型改造需求</h5>
          <el-form-item
            label="企业数字化改造意愿强烈程度"
            prop="digitalTransformationIntent"
          >
            <el-radio-group
              v-model="ruleForm.digitalTransformationIntent"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="强烈"></el-radio>
              <el-radio label="一般"></el-radio>
              <el-radio label="较弱"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="企业通过数字化转型拟投资额度"
            prop="plannedInvestmentForDigitalTransformation"
          >
            <el-radio-group
              v-model="ruleForm.plannedInvestmentForDigitalTransformation"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="50万以下"></el-radio>
              <el-radio label="100万以下"></el-radio>
              <el-radio label="200万以下"></el-radio>
              <el-radio label="300万以下"></el-radio>
              <el-radio label="400万以下"></el-radio>
              <el-radio label="500万以下"></el-radio>
              <el-radio label="数字化车间标准（800万）"></el-radio>
              <el-radio label="智能工厂标准（2000万）"></el-radio>
              <el-radio label="链主工厂标准（3000万）"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="企业当前急需进行数字化转型的业务场景或业务环节"
            prop="transformationNeeds"
          >
            <el-radio-group
              v-model="ruleForm.transformationNeeds"
              size="medium"
              :disabled="formType == 2"
            >
              <el-radio label="研发设计"></el-radio>
              <el-radio label="生产制造"></el-radio>
              <el-radio label="仓储物流"></el-radio>
              <el-radio label="市场营销"></el-radio>
              <el-radio label="产品/服务"></el-radio>
              <el-radio label="供应链管理"></el-radio>
              <el-radio label="运营管理（人力、财务、资产管理等）"></el-radio>
              <el-radio label="其他">
                <span>其他：</span>
                <el-input
                  class="other-input"
                  v-model="ruleForm.otherTransformationNeeds"
                  :disabled="formType == 2"
                />
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="在数字化转型方面最迫切的需求或数字化转型规划"
            prop="urgentNeedDigital"
          >
            <el-input
              type="textarea"
              class="limit_area"
              v-model="ruleForm.urgentNeedDigital"
              :autosize="{ minRows: 6 }"
              placeholder="文字描述500字以内，例如希望通过利用数字化技术实现供应商智能化管理、采购过程自动化和透明化、库存实时监控以及物流的智能化追踪等以降低生产经营成本。"
              maxlength="500"
              show-word-limit
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="是否有意向数字化转型服务商并建立联系或合作"
            prop="pullService"
          >
            <el-radio-group
              v-model="ruleForm.pullService"
              size="medium"
              :disabled="formType == 2"
              @change="changePullService"
            >
              <el-radio label="是">
                <span>是：</span>
                <el-select
                  v-model="selectPullService"
                  placeholder="请选择选择服务商"
                  clearable
                  :disabled="formType == 2 || ruleForm.pullService != '是'"
                >
                  <el-option
                    v-for="item in tractionUnitList_temp"
                    :key="item.id"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-radio>
              <el-radio label="否"></el-radio>
              <el-radio label="其他">
                <span>其他：</span>
                <el-input
                  class="other-input"
                  v-model="otherPullService"
                  :disabled="formType == 2 || ruleForm.pullService != '其他'"
              /></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="政策疑问">
            <el-input
              type="textarea"
              class="limit_area"
              v-model="ruleForm.policyQuestion"
              :autosize="{ minRows: 6 }"
              placeholder="请描述企业对自身申报条件符合性及政策理解方面的疑问"
              maxlength="500"
              show-word-limit
              :disabled="formType == 2"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-container">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm2"
          label-width="250px"
          class="ruleForm"
          label-position="left"
        >
          <h5 class="subheadings">三、附件材料</h5>

          <el-form-item label="营业执照" class="is-required">
            <el-upload
              ref="businessLicenseFileList"
              :action="uploadUrl"
              :limit="5"
              :on-exceed="handleFileExceed"
              :on-preview="handlePreview"
              :on-remove="
                (file) => handleRemove(file, 'businessLicenseFileList')
              "
              :on-success="
                (response, file) =>
                  handleFileSuccess(response, file, 'businessLicenseFileList')
              "
              :on-change="
                (file, fileList) =>
                  handleFileChange(file, fileList, 'businessLicenseFileList')
              "
              :file-list="businessLicenseFileList"
              :disabled="formType == 2"
            >
              <el-button size="small" type="primary" :disabled="formType == 2"
                >点击上传</el-button
              >
              <div slot="tip" class="el-upload__tip">
                注：上传营业执照 （最多可上传5个文件, 文件上传大小不能超过20M）
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="信用中国" class="is-required">
            <el-upload
              ref="creditChinaFileList"
              :action="uploadUrl"
              :limit="5"
              :on-exceed="handleFileExceed"
              :on-preview="handlePreview"
              :on-remove="(file) => handleRemove(file, 'creditChinaFileList')"
              :on-success="
                (response, file) =>
                  handleFileSuccess(response, file, 'creditChinaFileList')
              "
              :on-change="
                (file, fileList) =>
                  handleFileChange(file, fileList, 'creditChinaFileList')
              "
              :file-list="creditChinaFileList"
              :disabled="formType == 2"
            >
              <el-button size="small" type="primary" :disabled="formType == 2"
                >点击上传</el-button
              >
              <div slot="tip" class="el-upload__tip">
                注：请在“信用中国”网站（<el-link
                  type="primary"
                  target="_blank"
                  href="https://www.creditchina.gov.cn/home/xybgxzzn/"
                  >https://www.creditchina.gov.cn/home/xybgxzzn/</el-link
                >）下载信用信息报告。最多可上传5个文件,
                文件上传大小不能超过20M。
              </div>
            </el-upload>
          </el-form-item>
          <div>
            <el-button @click="handleClose" class="cancel-btn btn"
              >关闭</el-button
            >
            <el-button
              v-if="formType != 2"
              @click="saveDraft"
              class="draft-btn btn"
              >保存草稿</el-button
            >
            <el-button
              v-if="formType != 2"
              @click="getDraft"
              class="draft-btn btn"
              >获取草稿</el-button
            >
            <el-button
              v-if="formType != 2"
              @click="beforeSubmit"
              type="primary"
              class="btn"
              >提交</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import AreaSelect from "@/components/AreaSelect.vue";
import configs from "@/config/index";
import { getArea } from "@/api/login";
import {
  addOrUpdateDigitalReady,
  getCompanyInfo,
  getSelftResult,
  getDigitalReadyDetail,
} from "@/api/qy";
import { saveDraft, getDraft, getTractionUnit } from "@/api/pts";
export default {
  name: "declare",
  components: {
    AreaSelect,
  },
  data() {
    return {
      ruleForm: {
        enterpriseName: "",
        socialCreditCode: "",
        administrativeRegion: "",
        province: "",
        city: "",
        area: "",
        contactPerson: "",
        contactPhone: "",
        enterpriseIntroduction: "",
        subIndustry: null,
        smallerMediumEnterprise: null,
        manufacturingEnterprise: null,
        largeScaleEnterprise: null,
        specializedAndNewEnterprise: null,
        lastYearMoney: "",
        employeeCount: "",
        mainBusinessOrProduct: "",
        subIndustryProof: "",
        digitalTransformationIntent: null,
        plannedInvestmentForDigitalTransformation: null,
        transformationNeeds: null,
        otherTransformationNeeds: "",
        urgentNeedDigital: "",
        pullService: null,
        pullServiceName: "",
        // 所属细分行业开展业务证明材料文件
        subIndustryFile: "",
        // 营业执照
        businessLicenseFile: "",
        // 信用中国
        creditChinaFile: "",

        questionResultFile: {
          result: "",
          file: "",
        }, //数字化水平测评结果文件
        policyQuestion: "",
      },
      fileListAF: [],

      rules: {
        // 一
        enterpriseName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        socialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        administrativeRegion: [
          { required: true, message: "请选择所属行政区", trigger: "change" },
        ],
        contactPerson: [
          { required: true, message: "请输入联系人", trigger: "change" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" },
        ],
        enterpriseIntroduction: [
          { required: true, message: "请输入企业简介", trigger: "change" },
        ],
        subIndustry: [
          { required: true, message: "请输入所属细分行业", trigger: "change" },
        ],
        smallerMediumEnterprise: [
          {
            required: true,
            message: "请选择是否为中小企业",
            trigger: "change",
          },
        ],
        manufacturingEnterprise: [
          {
            required: true,
            message: "请选择是否为制造业企业",
            trigger: "change",
          },
        ],
        largeScaleEnterprise: [
          {
            required: true,
            message: "请选择是否为规上企业",
            trigger: "change",
          },
        ],
        rulesPlace: [
          {
            required: true,
            message: "请选择拟改造工厂是否在广州",
            trigger: "change",
          },
        ],
        specializedAndNewEnterprise: [
          {
            required: true,
            message: "请选择是否为专精特新企业",
            trigger: "change",
          },
        ],
        lastYearMoney: [
          {
            required: true,
            message: "请输入2024年营业收入（万元）",
            trigger: "change",
          },
        ],
        employeeCount: [
          {
            required: true,
            message: "请输入员工数量（人）",
            trigger: "change",
          },
        ],
        mainBusinessOrProduct: [
          {
            required: true,
            message: "请输入主营业务或主营产品",
            trigger: "change",
          },
        ],
        subIndustryProof: [
          {
            required: true,
            message: "请输入所属细分行业开展业务证明材料",
            trigger: "change",
          },
        ],
        digitalTransformationIntent: [
          {
            required: true,
            message: "请输入企业数字化改造意愿强烈程度",
            trigger: "change",
          },
        ],
        plannedInvestmentForDigitalTransformation: [
          {
            required: true,
            message: "请输入企业通过数字化转型拟投资额度",
            trigger: "change",
          },
        ],
        transformationNeeds: [
          {
            required: true,
            message: "请输入企业当前急需进行数字化转型的业务场景或业务环节",
            trigger: "change",
          },
        ],
        urgentNeedDigital: [
          {
            required: true,
            message: "请输入在数字化转型方面最迫切的需求或数字化转型规划",
            trigger: "change",
          },
        ],
        pullService: [
          {
            required: true,
            message: "请输入是否有意向数字化转型服务商并建立联系或合作",
            trigger: "change",
          },
        ],
        "questionResultFile.result": [
          {
            required: true,
            message: "请选择制造业中小企业数字化水平评估结果",
            trigger: "change",
          },
        ],
      },

      areaList: [],
      tractionUnitList_temp: [],
      selectPullService: "",
      otherPullService: "",

      // uploadUrl: configs.baseUrl2 + "/api/v1/common/upload", // 图片上传路径
      uploadUrl: configs.baseUrl2 + "/api/file/qiUpload", // 图片上传路径
      subIndustryFileList: [],
      businessLicenseFileList: [],
      creditChinaFileList: [],
      registeredAddress: {
        provinceId: null,
        cityId: null,
        areaId: null,
      },
    };
  },
  computed: {
    formType() {
      return Number(this.$route.query.type) || 1;
    },
  },
  mounted() {
    this.getGUANGZHOUAreaList();
    this.getTractionUnit1();
    if (this.formType === 1) {
      this.getCompanyInfo();
      this.getSelfAssessmentLevel();
    } else {
      this.getDetail();
    }
  },
  methods: {
    changePullService(val) {
      // console.log(val, "val--===");
      if (val == "其他") {
        this.selectPullService = "";
      } else if (val == "是") {
        this.otherPullService = "";
      }
    },
    getTractionUnit1() {
      getTractionUnit(1).then((res) => {
        // console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.tractionUnitList_temp = res.data.selectRespList;
        }
      });
    },
    getFile(file) {
      console.log(file, "0--");

      let a = document.createElement("a");
      a.href = file.url;
      a.setAttribute("download", name);
      a.style.display = "none";
      a.click();
    },
    getDetail() {
      getDigitalReadyDetail({
        // id: this.$route.query.id,
        creditCode: this.$route.query.creditCode,
      }).then((res) => {
        if (res.code == 200) {
          console.log("res", res);
          this.ruleForm = res.data;
          if (this.ruleForm.pullService == "是") {
            this.selectPullService = this.ruleForm.pullServiceName;
          } else if (this.ruleForm.pullService == "其他") {
            this.otherPullService = this.ruleForm.pullServiceName;
          }
          this.ruleForm.businessLicenseFile = JSON.parse(
            res.data.businessLicenseFile
          );
          this.ruleForm.creditChinaFile = JSON.parse(res.data.creditChinaFile);
          this.ruleForm.subIndustryFile = JSON.parse(res.data.subIndustryFile);
          this.ruleForm.questionResultFile = JSON.parse(
            res.data.questionResultFile
          );
          this.fileListAF = JSON.parse(this.ruleForm.questionResultFile.file);
          this.businessLicenseFileList = this.ruleForm.businessLicenseFile;
          this.creditChinaFileList = this.ruleForm.creditChinaFile;
          this.subIndustryFileList = this.ruleForm.subIndustryFile;

          this.registeredAddress = {
            provinceId: res.data.province,
            cityId: res.data.city,
            areaId: res.data.area,
          };
        }
      });
    },
    saveDraft() {
      if (this.ruleForm.pullService == "是") {
        this.ruleForm.pullServiceName = this.selectPullService;
      } else if (this.ruleForm.pullService == "其他") {
        this.ruleForm.pullServiceName = this.otherPullService;
      } else if (this.ruleForm.pullService == "否") {
        this.ruleForm.pullServiceName = "";
      }
      let pdata = {
        type: 8,
        content: JSON.stringify({
          ...this.ruleForm,
          subIndustryFileList: this.subIndustryFileList,
          businessLicenseFileList: this.businessLicenseFileList,
          creditChinaFileList: this.creditChinaFileList,
          fileListAF: this.fileListAF,
        }),
      };

      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存草稿成功");
        } else {
          this.$message.error("保存草稿失败");
        }
      });
    },
    getDraft() {
      let pdata = {
        type: 8,
      };

      this.subIndustryFileList = [];
      this.businessLicenseFileList = [];
      this.creditChinaFileList = [];

      getDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("获取成功");
          let data = JSON.parse(res.data.content ? res.data.content : "{}");
          this.ruleForm = data;
          if (this.ruleForm.pullService == "是") {
            this.selectPullService = this.ruleForm.pullServiceName;
          } else if (this.ruleForm.pullService == "其他") {
            this.otherPullService = this.ruleForm.pullServiceName;
          }
          this.subIndustryFileList = data.subIndustryFileList;
          this.businessLicenseFileList = data.businessLicenseFileList;
          this.creditChinaFileList = data.creditChinaFileList;
          this.fileListAF = data.fileListAF;
          this.registeredAddress = {
            provinceId: data.province,
            cityId: data.city,
            areaId: data.area,
          };
          delete this.ruleForm.subIndustryFileList;
          delete this.ruleForm.businessLicenseFileList;
          delete this.ruleForm.creditChinaFileList;
          delete this.ruleForm.fileListAF;
        } else {
          this.$message.error("获取失败");
        }
      });
    },
    getCompanyInfo() {
      getCompanyInfo().then((res) => {
        this.ruleForm.enterpriseName = this.ruleForm.enterpriseName
          ? this.ruleForm.enterpriseName
          : res.data.name;
        this.ruleForm.administrativeRegion = this.ruleForm.administrativeRegion
          ? this.ruleForm.administrativeRegion
          : res.data.belong_area;
        this.ruleForm.socialCreditCode = this.ruleForm.socialCreditCode
          ? this.ruleForm.socialCreditCode
          : res.data.jgm;
        this.ruleForm.contactPerson = this.ruleForm.contactPerson
          ? this.ruleForm.contactPerson
          : res.data.daily_person;
        this.ruleForm.contactPhone = this.ruleForm.contactPhone
          ? this.ruleForm.contactPhone
          : res.data.daily_mobile;
        // let level = "";
        // const score = res.data.score;

        // if (score < 20) {
        //   level = "无等级";
        // } else if (20 <= score && score < 40) {
        //   level = "一级";
        // } else if (40 <= score && score < 60) {
        //   level = "二级";
        // } else if (60 <= score && score < 80) {
        //   level = "三级";
        // } else if (80 <= score) {
        //   level = "四级";
        // }
        // this.ruleForm.questionResultFile.result = this.ruleForm
        //   .questionResultFile.result
        //   ? this.ruleForm.questionResultFile.result
        //   : level;
      });
    },

    // 获取自评测等级
    getSelfAssessmentLevel() {
      let pdata = {
        questionId: 1,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      getSelftResult(pdata).then((res) => {
        console.log("res", res);
        if (res.data.length > 0) {
          //返回小等级的数据
          this.ruleForm.questionResultFile.result =
            this.panlevel(res.data[0].levl) < this.panlevel(res.data[1].levl)
              ? res.data[0].levl
              : res.data[1].levl;
        } else {
          this.ruleForm.questionResultFile.result = "无";
        }
      });
    },
    // 判断等级
    panlevel(level) {
      if (level == "无") {
        return 0;
      } else if (level == "一级") {
        return 1;
      } else if (level == "二级") {
        return 2;
      } else if (level == "三级") {
        return 3;
      } else if (level == "四级") {
        return 4;
      }
    },
    handleGoPC() {
      this.$confirm("此操作将失去填写的内容, 请确认是否已保存草稿。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.$router.push("/selfAssessmentQuestion");
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    handleRemove(file, type) {
      switch (type) {
        case "subIndustryFileList":
          this.subIndustryFileList = this.subIndustryFileList.filter(
            (item) => item.url !== file.url
          );
          this.ruleForm.subIndustryFile = JSON.stringify(
            this.subIndustryFileList
          );
          // console.log(
          //   "subIndustryFileList",
          //   this.subIndustryFileList,
          //   this.ruleForm.subIndustryFile
          // );
          break;
        case "businessLicenseFileList":
          this.businessLicenseFileList = this.businessLicenseFileList.filter(
            (item) => item.url !== file.url
          );
          this.ruleForm.businessLicenseFile = JSON.stringify(
            this.businessLicenseFileList
          );
          // console.log("改成前---列表", this.businessLicenseFileList);
          // console.log("改成后----列表", this.ruleForm.businessLicenseFile);
          break;
        case "creditChinaFileList":
          this.creditChinaFileList = this.creditChinaFileList.filter(
            (item) => item.url !== file.url
          );
          this.ruleForm.creditChinaFile = JSON.stringify(
            this.creditChinaFileList
          );
          // console.log(
          //   "creditChinaFileList",
          //   this.creditChinaFileList,
          //   this.ruleForm.creditChinaFile
          // );
          break;
        default:
          this.$message.error("删除错误，请联系管理员！");
          break;
      }
      // console.log("删除-=-=-=-=-=-=-=-=-=->", file.name, type);
    },
    handlePreview(file) {
      window.open(file.url, "_blank");
      // console.log("-=-=-=-=-=-=-=-=-=->", file.url);
    },
    handleClose() {
      this.$router.push("/enterprise/sbzj");
    },
    beforeSubmit() {
      console.log("-----------------======", this.ruleForm);
      let flag = [];

      if (this.ruleForm.province && this.ruleForm.city && this.ruleForm.area) {
        flag.push(true);
      } else {
        this.$message.error("注册地址省份不能为空");
        flag.push(false);
        return false;
      }
      if (!this.ruleForm.subIndustryFile) {
        flag.push(false);
        this.$message.error("请上传所属细分行业开展业务证明材料");
        return false;
      }
      if (!this.ruleForm.businessLicenseFile) {
        flag.push(false);
        this.$message.error("请上传营业执照");
        return false;
      }
      if (!this.ruleForm.creditChinaFile) {
        flag.push(false);
        this.$message.error("请上传信用中国文件");
        return false;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      });
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      });
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      });
      if (flag.includes(false)) {
        this.$message.error("请检查表单是否填写完整");
        return false;
      }
      if (this.fileListAF.length == 0) {
        this.$message.error("请上传制造业中小企业数字化水平评估文件");
        return false;
        // flag = false;
      }
      // console.log("最后flag-=-=-<>", flag);
      let pdata = {
        ...this.ruleForm,
      };
      if (this.ruleForm.pullService == "是") {
        pdata.pullServiceName = this.selectPullService;
      } else if (this.ruleForm.pullService == "其他") {
        pdata.pullServiceName = this.otherPullService;
      } else if (this.ruleForm.pullService == "否") {
        pdata.pullServiceName = "";
      }
      pdata.questionResultFile = JSON.stringify(pdata.questionResultFile);

      console.log("最后提交的数据", pdata);
      addOrUpdateDigitalReady(pdata)
        .then((res) => {
          // console.log("res-=-=-=-=", res);
          if (res) {
            // console.log("最后");
            this.$message.success("申报成功");
          } else {
            this.$message.error("申报失败.......");
          }
        })
        .catch((err) => {
          // console.log("error", err);
          this.$message.error("申报失败");
        });
    },
    handleFileChange(file, fileList, type) {
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        if (file.response.code != 200) {
          file.status = "error";
        }
        this.loadingStatus.close();
        this.$refs[type].uploadFiles.pop();
      }
    },
    // beforeFileUpload(file) {
    //   return new Promise(async (resolve, reject) => {
    //     console.log("beforeFileUpload", file);
    //     if (file.size > 1024 * 1024 * 20) {
    //       this.$message.error("文件过大, 无法上传");
    //       reject();
    //     } else {
    //       resolve();
    //     }
    //   });
    // },
    handleFileSuccess(response, file, type) {
      console.log("handleFileSuccess", response, file, type);
      if (file.size > 1024 * 1024 * 20) {
        this.$message.error("文件过大, 无法上传");
        return false;
      }
      if (response.code == 200) {
        switch (type) {
          case "questionResultFile":
            this.fileListAF.push({
              name: file.name,
              url: response.data,
            });
            this.ruleForm.questionResultFile.file = JSON.stringify(
              this.fileListAF
            );
            break;
          case "subIndustryFileList":
            this.subIndustryFileList.push({
              name: file.name,
              url: response.data,
            });
            this.ruleForm.subIndustryFile = JSON.stringify(
              this.subIndustryFileList
            );
            // console.log(
            //   "subIndustryFileList",
            //   this.subIndustryFileList,
            //   this.ruleForm.subIndustryFile
            // );
            break;
          case "businessLicenseFileList":
            this.businessLicenseFileList.push({
              name: file.name,
              url: response.data,
            });
            this.ruleForm.businessLicenseFile = JSON.stringify(
              this.businessLicenseFileList
            );
            // console.log(
            //   "businessLicenseFileList",
            //   this.businessLicenseFileList,
            //   this.ruleForm.businessLicenseFile
            // );
            break;
          case "creditChinaFileList":
            this.creditChinaFileList.push({
              name: file.name,
              url: response.data,
            });
            this.ruleForm.creditChinaFile = JSON.stringify(
              this.creditChinaFileList
            );
            // console.log(
            //   "creditChinaFileList",
            //   this.creditChinaFileList,
            //   this.ruleForm.creditChinaFile
            // );
            break;
          default:
            this.$message.error("上传错误，请联系管理员！");
            break;
        }
      } else {
        this.$message.error(response.msg);
        return false;
      }
    },
    handleFileExceed() {
      this.$message.error("超过上传数量");
    },
    handleDelFile(index) {
      this.subIndustryFileList.splice(index, 1);
      this.ruleForm.subIndustryFile = JSON.stringify(this.subIndustryFileList);
    },

    handleDelFileAF(i) {
      this.fileListAF.splice(i, 1);
      this.ruleForm.questionResultFile.file = JSON.stringify(this.fileListAF);
    },

    handleRegisteredAddress(val) {
      this.ruleForm.province = val.provinceId;
      this.ruleForm.city = val.cityId;
      this.ruleForm.area = val.areaId;
    },
    // 区域下拉
    getGUANGZHOUAreaList() {
      getArea({ pid: 236 }).then((res) => {
        this.areaList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.other-input {
  ::v-deep .el-input__inner {
    height: 18px;
    width: 100px;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #efefef;
  }
}
.declare_main {
  background-color: #f0f5ff;
  .declare_body {
    background-color: #f0f5ff;
    // padding: 30px 0;
    .crumbs-content {
      width: 74%;
      margin: 0 auto 20px;
    }

    .form-container {
      width: 74%;
      padding: 40px 200px;
      background-color: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      margin: 0 auto 10px;

      .form-title-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .form-title {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          color: #333333;

          margin: 0;
          margin-bottom: 10px;
        }
        .line {
          width: 150px;
          height: 4px;
          border-radius: 2px;
          background: #205dc3;
        }
      }

      .subheadings {
        font-size: 22px;
        font-weight: 600;
        color: #333333;

        margin-top: 80px;
        margin-bottom: 40px;
      }
      .ruleForm {
        .radio-content {
          display: flex;
          span {
            font-size: 16px;
            line-height: 18px;
            color: #666666;

            margin-left: 50px;
          }
        }

        .zmcl-content {
          .zmcl-upload {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 5px;
            border: 1px solid #dcdfe6;
            margin-top: 20px;

            .zmcl-content-left {
              width: 70%;
              font-size: 16px;
              line-height: 26px;
              color: #2282f9;
            }
          }
        }

        .radio-content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .other {
            margin-left: -20px;
            border-bottom: 1px solid #dcdfe6;

            ::v-deep .el-input__inner {
              border: none;
            }
          }
        }

        .qyms-radio-group {
          display: flex;
          flex-direction: column;

          ::v-deep .qyms-radio-option {
            display: flex;
            margin-bottom: 30px;
            .el-radio__label {
              display: inline-block;
              display: flex;
              flex-direction: column;
              .qyms-description {
                width: 100%;
                margin-top: 10px;

                font-size: 14px;
                line-height: 26px;
                color: #666666;
                text-wrap: wrap;
              }
            }
          }
        }

        .checkbox-content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .other {
            // margin-left: -20px;
            border-bottom: 1px solid #dcdfe6;

            ::v-deep .el-input__inner {
              border: none;
            }
          }
        }

        .qydw-content {
          .qydw-radio {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            margin-left: 30px;

            .qydw-item {
              width: 350px;
              display: flex;
              flex-direction: column;
              margin-right: 30px;

              .qydw-item-title {
                font-size: 18px;
                font-weight: 600;
                color: #333333;

                margin-bottom: 30px;
              }

              .qydw-radio-option {
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
}
.draft-btn {
  height: 38px;
  background-color: #ea7100;
  border: none;
  color: #fff;
  &:hover {
    background-color: #ffb774;
    color: #fff;
  }
}
</style>
