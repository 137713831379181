// 政府
import Header from "@/components/CenterHeader";
import Footer from "@/components/Footer.vue";
import government from "@/views/zf/index";
import Dxxcx from "@/views/zf/dxxcx/index";
import CoursePublic from "@/views/zf/coursePublic/index";
import CourseForm from "@/views/zf/coursePublic/form";
import ApprovalCenter from "@/views/zf/approvalCenter/index";
// 申报征集首页
import Application from "@/views/zf/application/index";
// 申报征集列表
import ApplicationList from "@/views/zf/application/list";
import ApplicationListSecond from "@/views/zf/application/listSecond";
import ApplicationListThird from "@/views/zf/application/listThird";
import ApplicationListFourth from "@/views/zf/application/listFourth";
import ApplicationListFifth from "@/views/zf/application/listFifth";
// 申报征集表单
import ApplicationForm from "@/views/zf/application/form";
// 申报征集表单2
import ApplicationFormSecond from "@/views/zf/application/formSecond";
import ApplicationFormThird from "@/views/zf/application/formThird";
import ApplicationFormFourth from "@/views/zf/application/formFourth";
import ApplicationFormFifth from "@/views/zf/application/formFifth";
export default [
  {
    path: "/government/index",
    name: "government",
    components: {
      header: Header,
      default: government, //默认省略不写name的情况
      footer: Footer,
      meta: {
        keepAlive: true,
      },
    },
    children: [
      {
        path: "siHuaZhenDuan",
        name: "SiHuaZhenDuan",
        components: {
          header: Header,
          default: Dxxcx,
          footer: Footer,
        },
      },
      // TODO o_0'''
    ],
  },

  {
    path: "/government/course",
    name: "CoursePublic",
    components: {
      header: Header,
      default: CoursePublic,
      footer: Footer,
    },
  },
  {
    path: "/government/course/add",
    name: "AddCourse",
    components: {
      header: Header,
      default: CourseForm,
      footer: Footer,
    },
  },
  {
    path: "/government/course/edit",
    name: "EditCourse",
    components: {
      header: Header,
      default: CourseForm,
      footer: Footer,
    },
  },
  {
    path: "/government/approval",
    name: "ApprovalCenter",
    components: {
      header: Header,
      default: ApprovalCenter,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj",
    name: "Application",
    components: {
      header: Header,
      default: Application,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/list",
    name: "ApplicationList",
    components: {
      header: Header,
      default: ApplicationList,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/listSecond",
    name: "ApplicationListSecond",
    components: {
      header: Header,
      default: ApplicationListSecond,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/listThird",
    name: "ApplicationListThird",
    components: {
      header: Header,
      default: ApplicationListThird,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/listFourth",
    name: "ApplicationListFourth",
    components: {
      header: Header,
      default: ApplicationListFourth,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/listFifth",
    name: "ApplicationListFifth",
    components: {
      header: Header,
      default: ApplicationListFifth,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/form",
    name: "ApplicationForm",
    components: {
      header: Header,
      default: ApplicationForm,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/formSecond",
    name: "ApplicationFormSecond",
    components: {
      header: Header,
      default: ApplicationFormSecond,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/formThird",
    name: "ApplicationFormThird",
    components: {
      header: Header,
      default: ApplicationFormThird,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/formFourth",
    name: "ApplicationFormFourth",
    components: {
      header: Header,
      default: ApplicationFormFourth,
      footer: Footer,
    },
  },
  {
    path: "/government/sbzj/formFifth",
    name: "ApplicationFormFifth",
    components: {
      header: Header,
      default: ApplicationFormFifth,
      footer: Footer,
    },
  },
  // {
  //   path: "/government/dxxcx",
  //   name: "zfdccxc",
  //   components: {
  //     header: Header,
  //     default: Dxxcx,
  //     footer: Footer,
  //     meta: {
  //       keepAlive: true
  //     }
  //   }
  // },
  // {
  //   path: "/government/xxcx",
  //   name: "zfxxcx",
  //   components: {
  //     header: Header,
  //     default: Xxcx,
  //     footer: Footer,
  //     meta: {
  //       keepAlive: true
  //     }
  //   }
  // },
];
