<template>
  <div class="main" :style="$bgImg('sihua_bg.png')">
    <div class="container">
      <div class="add-btn">
        <div
          class="add-btn-body pointer flex column center"
          @click="openResultDialog"
        >
          <img class="add-btn-icon" :src="'/watchresult.png' | staticMedia" />
          <span class="add-btn-title">查看问卷结果</span>
        </div>
      </div>
      <div class="question-list">
        <div
          class="question-header flex column horizontally"
          :style="questionHeaderImg"
        >
          <h3 class="question-header-title">{{ title }}</h3>
          <!-- 临时修改 2  -->
          <!-- <div class="question-header-tips">
            广州市教师能力自评估表
          </div> -->
          <div class="question-header-tips">
            1、为评估制造业企业“四化”水平，本评测表从基础要素、数字化转型、网络化协同、智能化制造、绿色化提升5个方面定位制造业企业现阶段“四化”转型的优势和短板。
          </div>
          <div class="question-header-tips">
            2、为准确、客观、全面地反映企业“四化”真实水平，建议由企业信息化部门会同研发、采购、生产、财务、市场等所有与“四化”相关的部门联合填写。评测表内容仅供诊断企业
          </div>
        </div>
        <div class="question-form">
          <!-- 临时修改 2  -->
          <h3 class="question-form-title">企业基本情况</h3>
          <!-- <h3 class="question-form-title">学校基本情况</h3> -->
          <el-form
            ref="infoForm"
            :model="infoForm"
            label-width="197px"
            label-position="left"
            size="mini"
            :rules="rules"
          >
            <!-- 临时修改 2  -->
            <el-form-item label="企业名称：" prop="qi_name">
              <!-- <el-form-item label="学校名称：" prop="qi_name"> -->
              <el-input
                v-model="infoForm.qi_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="三证合一码/组织机构代码：" prop="t_in_one">
              <!-- <el-form-item label="学校代码：" prop="t_in_one"> -->
              <el-input
                v-model="infoForm.t_in_one"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="企业联系人：" prop="qi_username">
              <!-- <el-form-item label="学校联系人：" prop="qi_username"> -->
              <el-input
                v-model="infoForm.qi_username"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="企业电话：" prop="qi_telephone">
              <!-- <el-form-item label="学校电话：" prop="qi_telephone"> -->
              <el-input
                v-model="infoForm.qi_telephone"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="企业地址：" prop="qi_address">
              <!-- <el-form-item label="学校地址：" prop="qi_address"> -->
              <el-input
                v-model="infoForm.qi_address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- 临时修改 2  -->
            <el-form-item label="企业所属行业：" prop="sshy">
              <el-checkbox-group v-model="infoForm.sshy">
                <el-checkbox
                  label="电子信息工业"
                  value="电子信息工业"
                ></el-checkbox>
                <el-checkbox label="装备工业" value="装备工业"></el-checkbox>
                <el-checkbox label="汽车产业" value="汽车产业"></el-checkbox>
                <el-checkbox
                  label="消费品工业"
                  value="消费品工业"
                ></el-checkbox>
                <el-checkbox label="材料工业" value="材料工业"></el-checkbox>
                <el-checkbox
                  label="生物医药行业"
                  value="生物医药行业"
                ></el-checkbox>
                <el-checkbox
                  label="工业节能行业"
                  value="工业节能行业"
                ></el-checkbox>
                <el-checkbox
                  label="电力、燃气及水生产和供应业"
                  value="电力、燃气及水生产和供应业"
                ></el-checkbox>
                <el-checkbox label="其他" value="其他"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <!-- <el-form-item label="学校类型：" prop="sshy">
              <el-checkbox-group v-model="infoForm.sshy">
                <el-checkbox label="小学" value="小学"></el-checkbox>
                <el-checkbox label="初中" value="初中"></el-checkbox>
                <el-checkbox
                  label="高中"
                  value="高中"
                ></el-checkbox>
                <el-checkbox label="职中" value="职中"></el-checkbox>
                <el-checkbox
                  label="大学"
                  value="大学"
                ></el-checkbox>
                <el-checkbox label="其他" value="其他"></el-checkbox>
              </el-checkbox-group>
            </el-form-item> -->
            <!-- 临时修改 2  -->
            <el-form-item label="企业性质：" prop="qyxz">
              <el-radio-group v-model="infoForm.qyxz">
                <el-radio label="国有企业" value="国有企业"></el-radio>
                <el-radio label="民营企业" value="民营企业"></el-radio>
                <el-radio label="三资企业" value="三资企业"></el-radio>
                <el-radio label="其他" value="其他"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业规模：" prop="qygm">
              <el-radio-group v-model="infoForm.qygm">
                <el-radio label="大型企业" value="大型企业"></el-radio>
                <el-radio label="中型企业" value="中型企业"></el-radio>
                <el-radio label="小型企业" value="小型企业"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上年度企业营业收入：" prop="sys">
              <div class="question-form-item flex vertical">
                <el-input
                  v-model="infoForm.sys"
                  placeholder="请输入"
                ></el-input>
                <span class="question-form-item-unit">万元</span>
              </div>
            </el-form-item>
            <el-form-item label="上年末企业员工人数：" prop="srs">
              <el-input v-model="infoForm.srs" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="企业信息系统建设情况：" prop="build">
              <el-input
                v-model="infoForm.build"
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="企业简介：" prop="introduction">
              <el-input
                v-model="infoForm.introduction"
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <el-form
            ref="questionForm2"
            label-width="40px"
            size="mini"
            class="question-form2"
          >
            <div v-for="(item, index) in formList" :key="index">
              <h4 class="question-form2-h4 first">
                {{ index + 1 }}、{{ item.name }}
              </h4>
              <div
                v-for="(sItem, sIndex) in item.s_cate"
                :key="index + '.' + sIndex"
                class="pl24"
              >
                <h5 class="question-form2-h5 second">
                  {{ index + 1 }}.{{ sIndex + 1 }} {{ sItem.name }}
                </h5>
                <div
                  v-for="(tItem, tIndex) in sItem.ti_data"
                  :key="index + '.' + sIndex + '.' + tIndex"
                  class="pl24"
                >
                  <h5 class="question-form2-h5 third">
                    {{ index + 1 }}.{{ sIndex + 1 }}.{{ tIndex + 1 }}
                    {{ tItem.name }}
                  </h5>
                  <!--is_type: {{ tItem.is_type }}-->
                  <!--单选题-->
                  <el-form-item v-if="tItem.is_type == '1'">
                    <el-radio-group v-model="tItem.answer" class="mt5">
                      <el-radio
                        v-for="(answer, aIndex) in tItem.content"
                        :key="aIndex"
                        :label="answer.key"
                        >{{ answer.key + ". " + answer.value }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <!--多选题-->
                  <el-form-item v-if="tItem.is_type == '2'">
                    <el-checkbox-group v-model="tItem.answer">
                      <el-checkbox
                        v-for="(answer, aIndex) in tItem.content"
                        :key="aIndex"
                        :label="answer.key"
                        >{{ answer.key + ". " + answer.value }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                  <!--判断题-->
                  <el-form-item v-if="tItem.is_type == '3'">
                    <el-switch v-model="tItem.answer"></el-switch>
                  </el-form-item>
                  <!--简单题-->
                  <el-form-item v-if="tItem.is_type == '4'">
                    <el-input
                      v-model="tItem.answer"
                      class="mt5"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="question-form2-btns flex horizontally">
              <el-button class="primaryBtn1 btn" @click="submit"
                >提交</el-button
              >
              <el-button class="cancleBtn1 btn" @click="cancleSubmit"
                >取消</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog
      title="选择问卷结果"
      :visible.sync="resultDialog"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="result-btn flex vertical" @click="getSHQuestionResult">
        <img class="result-btn-img" :src="'/sic2.png' | staticMedia" alt="" />
        <!-- 临时修改 2  -->
        “四化”能力自评估问卷结果
        <!-- 教师能力自评估问卷结果 -->
      </div>
    </el-dialog>
    <!-- 临时修改 2  -->
    <el-dialog
      title="“四化”能力自评估问卷结果"
      :visible.sync="siHuaResultDialog"
      width="888px"
      top="3vh"
      :close-on-click-modal="false"
    >
      <!-- <el-dialog
      title="教师能力自评估问卷结果"
      :visible.sync="siHuaResultDialog"
      width="888px"
      top="3vh"
      :close-on-click-modal="false"
    > -->
      <div class="sihua-result">
        <div class="sihua-result-img">
          <img
            class="sihua-result-image"
            :src="'/resultbac.png' | staticMedia"
            alt=""
          />
        </div>
        <div class="sihua-part">
          <h4 class="sihua-part-title flex vertical">
            <!-- 临时修改 2  -->
            <span class="blue-point"></span>企业“四化水平”得分情况
            <!-- <span class="blue-point"></span>教师能力自评估得分情况 -->
          </h4>
          <div class="sihua-part-text">
            <!-- 临时修改 2  -->
            <p>
              您公司在基础要素、数字化转型
              、网络化协同、智能化制造、绿色化提升五个一级指标得分如下表所示，分别体现您的自评估得分和评估诊断机构的评估得分。
            </p>
            <!-- <p>
              您学校在基础要素、数字化转型
              、网络化协同、智能化制造、绿色化提升五个一级指标得分如下表所示，分别体现您的自评估得分和评估诊断机构的评估得分。
            </p> -->
          </div>
          <div class="sihua-part-table">
            <div class="sihua-part-table-title flex horizontally">
              <div class="sihua-table-title-item">
                一级指标
              </div>
              <div class="sihua-table-title-item">
                自评估得分
              </div>
              <div class="sihua-table-title-item">
                诊断得分
              </div>
            </div>
            <div
              class="sihua-part-table-list flex horizontally"
              v-for="(item, index) in tipList"
              :key="index"
              v-show="
                item.name != '其他相关信息' &&
                  item.name != '其他相关信息（不计分）'
              "
            >
              <div class="sihua-table-list-item">
                {{ item.name }}
              </div>
              <div class="sihua-table-list-item">
                {{ item.statistical }}
              </div>
              <div class="sihua-table-list-item">
                {{ zdScoreList[index].statistical }}
              </div>
            </div>
            <div class="sihua-part-table-list flex horizontally">
              <div class="sihua-table-list-item">
                综合得分
              </div>
              <div class="sihua-table-list-item">
                {{ dataList.total }}
              </div>
              <div class="sihua-table-list-item">
                {{ dataList.total2 }}
              </div>
            </div>
            <div class="sihua-part-table-list flex horizontally">
              <!-- 临时修改 2  -->
              <div class="sihua-table-list-item">
                企业评定等级
              </div>
              <!-- <div class="sihua-table-list-item">
                学校评定等级
              </div> -->
              <div class="sihua-table-list-item">
                {{
                  dataList.level == 1
                    ? "一级"
                    : dataList.level == 2
                    ? "二级"
                    : dataList.level == 3
                    ? "三级"
                    : dataList.level == 4
                    ? "四级"
                    : "五级"
                }}
              </div>
              <div class="sihua-table-list-item">
                {{
                  dataList.level2 == null || dataList.level2 == undefined
                    ? "一级"
                    : dataList.level2 == 1
                    ? "一级"
                    : dataList.level2 == 2
                    ? "二级"
                    : dataList.level2 == 3
                    ? "三级"
                    : dataList.level2 == 4
                    ? "四级"
                    : "五级"
                }}
              </div>
            </div>
          </div>
          <div class="sihua-part-text">
            <p>
              <b class="sihua-part-text-weight">各等级主要特征描述如下：</b>
            </p>
            <p>
              <b class="sihua-part-text-weight">一级(0-20分):</b
              >企业对“四化”转型开始进行规划和资金投入。业务数据基本已采用信息技术手段，数据收集应用仅限于本部门或与财务管理部门进行分享，供应链和生产、研发设计环节尚未开始应用信息系统进行管理，尚未开始节能减排活动。企业的自动化和精益化水平较低。
            </p>
            <p>
              <b class="sihua-part-text-weight">二级(20-40分):</b
              >企业已制定“四化”相关发展战略规划，对支撑核心业务的设备和系统进行投资；主要设备具备数据采集和通信的能力，通过标准化的接口和数据格式，部分支撑生产作业的信息系统能够实现内部集成；数据共享只体现在单一业务活动中。具备一定的节能减排意识。
            </p>
            <p>
              <b class="sihua-part-text-weight">三级(40-60分):</b
              >企业实现对重要的制造业务、生产设备、生产单元的数字化、网络化改造；能够实现设计、生产、销售、物流、服务等核心业务间的信息系统集成，开始聚焦工厂范围内数据的共享；已实施节能减排相关的管理制度，并由专职人员进行管理。
            </p>
            <p>
              <b class="sihua-part-text-weight">四级(60-80分):</b
              >各信息系统已完成了集成，具备了跨业务活动的数据共享；已实现对人员、装备、制造、产品、环境等数据挖掘与价值分析，具备数据中台、数据模型库、知识库等工具和手段，通过数据分析优化生产工艺和业务流程，能够对核心业务活动实现精准预测与优化；已初步利用信息技术手段进行上下游供应链的协同；已上线或正在实施能源管理、节能减排监测系统项目。
            </p>
            <p>
              <b class="sihua-part-text-weight">五级(80分及以上):</b
              >数据驱动的业务创新和优化，衍生出新的业务模式和商业模式，实现大数据、机器学习、人工智能技术的平台工具的广泛应用及整个生产圈的社会化协作；实现供应链上下游的协同和生产的柔性化并适用大规模定制生产模式，具备设计制造一体化运作模式。
            </p>
          </div>
          <div class="sihua-part">
            <h4 class="sihua-part-title flex vertical">
              <!-- 临时修改 2  -->
              <span class="blue-point"></span>企业一级指标得分雷达图
              <!-- <span class="blue-point"></span>学校一级指标得分雷达图 -->
            </h4>
            <div class="sihua-echarts" ref="sihuaEcharts"></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>
<script>
import { getSHQuestionList, setSHQuestion, getSHResult } from "@/api/qy";
export default {
  components: {},
  data() {
    const validatePhone = (rule, value, callback) => {
      const phone = /^1\d{10}$/; //以1开头的数字
      if (value == "") {
        callback(new Error("企业电话格式不正确"));
      } else if (!phone.test(value) && value) {
        callback(new Error("企业电话格式不正确"));
      } else {
        callback();
      }
    };
    return {
      resultDialog: false,
      siHuaResultDialog: false,
      // 临时修改 2
      title: "制造业中小企业数字化水平评估问卷",
      // title: "广州市教师能力自评估表",
      infoForm: {
        qi_name: "",
        t_in_one: "",
        qi_username: "",
        qi_telephone: "",
        qi_address: "",
        sshy: [],
        qyxz: "",
        qygm: "",
        sys: "",
        srs: "",
        build: "",
        introduction: "",
      },
      rules: {
        qi_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        t_in_one: [
          {
            required: true,
            message: "请输入三证合一码/组织机构代码",
            trigger: "blur",
          },
        ],
        qi_username: [
          { required: true, message: "请输入企业联系人", trigger: "blur" },
        ],
        qi_telephone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        qi_address: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        sshy: [
          { required: true, message: "请选择企业所属行业", trigger: "blur" },
        ],
        qyxz: [{ required: true, message: "请选择企业性质", trigger: "blur" }],
        qygm: [{ required: true, message: "请选择企业规模", trigger: "blur" }],
        sys: [
          {
            required: true,
            message: "请输入上年度企业营业收入",
            trigger: "blur",
          },
        ],
        srs: [
          {
            required: true,
            message: "请输入上年末企业员工人数",
            trigger: "blur",
          },
        ],
        build: [
          {
            required: true,
            message: "请输入企业信息系统建设情况",
            trigger: "blur",
          },
        ],
        introduction: [
          { required: true, message: "请输入企业简介", trigger: "blur" },
        ],
      },
      formList: [],
      tipList: [],
      dataList: {},
      zdScoreList: [],

      option: {
        tooltip: {
          show: true,
        },
        radar: [
          {
            indicator: [
              { text: "基础要素", color: "#205dc3", min: 0, max: 10 },
              { text: "绿色化提升", color: "#205dc3", min: 0, max: 10 },
              { text: "智能化制造", color: "#205dc3", min: 0, max: 10 },
              { text: "网络化协同", color: "#205dc3", min: 0, max: 10 },
              { text: "数字化转型", color: "#205dc3", min: 0, max: 10 },
            ],
            center: ["50%", "50%"],
            radius: 215,
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            scale: true,
            axisName: {
              formatter: "{value}",
              color: "#205dc3",
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["#64AFE945", "#64AFE990", "#64AFE9", "#428BD4"],
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            lineStyle: {
              width: 4,
            },
            emphasis: {
              lineStyle: {
                width: 5,
              },
            },
            data: [
              {
                value: [10, 20, 10, 10, 10],
                name: "",
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    questionHeaderImg() {
      const filteredImageUrl = this.$options.filters.staticMedia(
        "/text_table_bg.png"
      );
      return {
        backgroundImage: `url(${filteredImageUrl})`,
      };
    },
  },
  created() {},
  mounted() {
    this.getQuestionList();
  },
  methods: {
    getSHQuestionResult() {
      let _this = this;
      _this.siHuaResultDialog = true;
      _this.resultDialog = false;

      getSHResult().then((res) => {
        console.log("res", res);
        _this.dataList = res.data;
        _this.tipList = res.data.data;
        _this.zdScoreList = res.data.data2;

        let ayy = [];
        let sna = [];

        // 默认最大为35
        let maxScore = 0;
        _this.tipList.forEach((item) => {
          if (
            item.name != "其他相关信息" &&
            item.name != "其他相关信息（不计分）"
          ) {
            if (maxScore < Number(item.statistical)) {
              maxScore = item.statistical;
            }
          }
        });

        _this.tipList.forEach((item) => {
          if (
            item.name != "其他相关信息" &&
            item.name != "其他相关信息（不计分）"
          ) {
            let obj = {
              text: item.name,
              color: "#205dc3",
              min: 0,
              max: maxScore,
            };
            ayy.push(obj);
            sna.push(item.statistical);
          }
        });

        _this.option.radar[0].indicator = ayy;
        _this.option.series[0].data[0].value = sna;

        setTimeout(() => {
          _this.chartDom = _this.$refs.sihuaEcharts;
          _this.myChart = _this.$echarts.init(_this.chartDom);
          _this.myChart.setOption(_this.option);
        }, 500);
      });
    },
    getQuestionList() {
      let _this = this;
      getSHQuestionList()
        .then((res) => {
          console.log("res", res);
          _this.formList = _this.handleAnswer(res.data.p_cate);
          _this.infoForm.qi_name = res.data.name;
          _this.infoForm.t_in_one = res.data.jgm;
          _this.infoForm.qi_username = res.data.truename;
          _this.infoForm.qi_telephone = res.data.mobile;
          _this.infoForm.qi_address = `${res.data.province}${res.data.city}${res.data.area}`;
        })
        .finally(() => {});
    },
    submit() {
      let _this = this;
      const answer = _this.getAnswer();
      let form = _this.infoForm;
      console.log("this.", form, this.formList, answer);
      let loading = this.$loading({
        lock: true,
        text: "提交中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let pdata = {
        qi_name: form.qi_name || "",
        t_in_one: form.t_in_one || "",
        qi_username: form.qi_username || "",
        qi_telephone: form.qi_telephone || "",
        qi_address: form.qi_address || "",
        sshy: form.sshy || [],
        qyxz: form.qyxz || "",
        qygm: form.qygm || "",
        sys: form.sys || "",
        srs: form.srs || "",
        introduction: form.introduction || "",
        build: form.build || "",
        answer: JSON.stringify(answer),
      };

      setSHQuestion(pdata)
        .then((res) => {
          _this.$message.success(res.msg);
          setTimeout(() => {
            _this.$router.go(0);
          }, 1500);
        })
        .catch((err) => {
          _this.$message.error(err || err.msg);
        })
        .finally(() => {
          loading.close();
          setTimeout(() => {
            document.documentElement.scrollTop = 0;
          }, 1500);
        });
    },
    openResultDialog() {
      this.resultDialog = true;
    },
    cancleSubmit() {
      let _this = this;
      this.$confirm("退出后，将会清除已答题目", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$router.go();
          this.$message({
            type: "success",
            message: "已退出",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleAnswer(list) {
      list.forEach((el) => {
        if (el.s_cate && el.s_cate.length) {
          el.s_cate.forEach((e) => {
            if (e.ti_data && e.ti_data.length) {
              e.ti_data.forEach((tiObj) => {
                if (tiObj.is_type == "2") {
                  tiObj.answer = [];
                }
              });
            }
          });
        }
      });
      return list;
    },
    getAnswer() {
      var answer = {};
      this.formList.forEach(function(el) {
        if (el.s_cate && el.s_cate.length) {
          answer[el.id] = {};
          el.s_cate.forEach(function(e) {
            if (e.ti_data && e.ti_data.length) {
              answer[el.id][e.id] = {};
              e.ti_data.forEach(function(tiObj) {
                if (typeof tiObj.answer == "string") {
                  answer[el.id][e.id][tiObj.id] = tiObj.answer
                    ? [tiObj.answer]
                    : [];
                } else {
                  answer[el.id][e.id][tiObj.id] = tiObj.answer || [];
                }
              });
            }
          });
        }
      });
      return answer;
    },
  },
};
</script>
<style lang="less" scoped>
.el-button + .el-button {
  margin-left: 30px;
}
.container {
  position: relative;
  .question-list {
    width: 84%;
    margin: 0 auto;
    .question-header {
      width: 100%;
      height: 300px;
      background-size: 100% 100%;
      color: #fff;
      padding: 0 2% 0 32%;
      margin-bottom: 20px;
      box-sizing: border-box;
      .question-header-title {
        font-size: 24px;
        line-height: 36px;
        height: 36px;
        margin-bottom: 20px;
      }

      .question-header-tips {
        line-height: 32px;
        font-size: 15px;
      }
    }
    .question-form {
      background-color: #fff;
      padding: 40px 200px;
      border-radius: 10px;
      .question-form-title {
        position: relative;
        margin-bottom: 88px;
        font-size: 24px;
        text-align: center;
        &::before {
          content: "";
          width: 300px;
          height: 4px;
          position: absolute;
          display: inline-block;
          width: 250px;
          height: 4px;
          background-color: #205dc3;
          bottom: -10px;
          right: 0;
          left: 0;
          margin: 0 auto;
          border-radius: 3px;
        }
      }
      .question-form-item {
        .question-form-item-unit {
          display: inline-block;
          width: 35px;
          text-align: left;
          margin-left: 10px;
          font-size: 14px;
          color: #606266;
        }
      }
      .el-form {
        width: 84%;
      }
      .question-form2 {
        .question-form2-h4,
        .question-form2-h5 {
          font-size: 14px;
          color: #606266;
        }
        .first {
          padding: 8px 0;
        }
        .second {
          padding: 5px 0;
          text-indent: 2rem;
        }
        .third {
          padding: 5px 0;
          text-indent: 4rem;
          line-height: 1.5;
        }
        .el-form-item--mini.el-form-item {
          padding: 0 2rem;
        }
        .el-radio {
          margin-bottom: 10px;
        }
        .el-checkbox {
          display: flex;
          white-space: pre-wrap;
          .el-checkbox__input {
            padding-top: 3px;
          }
        }
      }
    }
  }
}
.result-btn {
  width: fit-content;
  height: 80px;
  font-size: 18px;
  color: #333;
  padding: 0 10px;
  &:hover {
    color: #fff;
    background-color: #205dc3;
    border-radius: 10px;
  }
  .result-btn-img {
    width: 46px;
    height: 46px;
    margin-right: 10px;
  }
}
.sihua-result {
  padding: 0 50px 10px;
  .sihua-result-image {
    width: 100%;
  }
  .sihua-part {
    padding: 10px 0;
    .sihua-part-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      .blue-point {
        width: 10px;
        height: 10px;
        background-color: #205dc3;
        border-radius: 10px;
        margin: 0 5px;
      }
    }
    .sihua-part-text {
      line-height: 1.5;
      margin-top: 10px;
      margin-left: 20px;
      color: #333;
      .sihua-part-text-weight {
        color: #333;
        font-weight: 600;
      }
    }
    .sihua-part-table {
      width: 560px;
      margin: 10px auto;
      border: 1px solid #d8d8d8;
      .sihua-part-table-title {
        .sihua-table-title-item {
          width: 186px;
          height: 46px;
          line-height: 46px;
          background-color: #205dc3;
          text-align: center;
          color: #fff;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
      .sihua-part-table-list {
        &:last-child {
          .sihua-table-list-item {
            border-bottom: none;
          }
        }
        .sihua-table-list-item {
          width: 186px;
          height: 56px;
          line-height: 56px;
          color: #333;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .sihua-echarts {
      width: 600px;
      height: 500px;
      margin: 10px auto;
    }
  }
}
</style>
